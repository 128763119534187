import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-multi-carousel/lib/styles.css';

import Header from "../components/Header";
import Footer from "../components/Footer";


class ChangePhone extends Component {
    
    render() {   
      return (
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-1 pb-4">
                            <div className="row">
                                <div className="col-12">
                                    <h3>My Account</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                <div className="form-div">
                                    <center><h4>Change Phone Number</h4></center>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New Phone Number</Form.Label>
                                        <Form.Control size="lg" type="tel" placeholder="Enter your new phone number" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control size="lg" type="password" placeholder="Enter your password" />
                                    </Form.Group>
                                    <Button size="lg" variant="primary" type="submit" className="w-100 mt-3">Submit</Button>
                                </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default ChangePhone
