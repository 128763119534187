import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

import { Link } from 'react-router-dom';

const responsiveVOD = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};

class Vods extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contents: [],
            loading: true,
        }
    }
        
    componentDidMount(){
        //console.log(window.backendDomain);
        if(this.state.loading === true){
            fetch(window.backendDomain+'/api/v1/contents/vods/view', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                if (json.status === 'OK') {
                    this.setState({contents: json.data});
                    this.setState({loading: false});
                }
            });
        }
    }
    
    render() {  
        return (
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        {
                            this.state.loading === true ?
                            <div className="mt-5">
                                <Loader />   
                            </div>
                            :
                            <>
                                {
                                    this.state.contents.content[1].status === 1 ?
                                    <>
                                        <br />
                                        <hr />
                                        <div className="row">
                                            <div className="col-6 p-0">
                                                <h3>Video on Demand</h3>
                                            </div>
                                            <div className="col-6 p-0">
                                                <Link to="/vods/all" className="link pull-right"><h6>All VOD</h6></Link>
                                            </div>
                                        </div>
                                        { 
                                            this.state.contents.vodsCategory.map((item) => (
                                                <div>
                                                    <div className="row" key={item._id}>
                                                        <div className="col-12 p-1">
                                                            <div className="row">
                                                                <div className="col-6 ">
                                                                    <h4>{item.name}</h4>
                                                                </div>
                                                                <div className="col-6">
                                                                    <Link to={`/vods/${item._id}`} className="link pull-right"><h6>More</h6></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 p-0">
                                                            <Carousel
                                                                swipeable={true}
                                                                draggable={true}
                                                                showDots={false}
                                                                responsive={responsiveVOD}
                                                                infinite={false}
                                                                keyBoardControl={true}
                                                                >
                                                                {
                                                                    this.state.contents.vodsList.filter(vods => vods.category === item._id).map((vodItem) => (
                                                                        <div className="p-1" key={vodItem._id}>
                                                                            <Link to={`/vod/${vodItem.tag}`} className="link">
                                                                                <img src={window.backendUrl+`/${vodItem.image}`} alt={vodItem.name} className="image" width="100%" />
                                                                                <div className="col-12">
                                                                                    <center><h6>{vodItem.name}</h6></center>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </Carousel>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                    :
                                    <></>
                                }
                            </>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default Vods
