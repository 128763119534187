import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 
import { usePaystackPayment } from 'react-paystack';

import Header from "../components/Header";
import Footer from "../components/Footer";
import loadingImage from '../images/loader.gif';
import cardImage from '../images/pay.png';

class AdditionalDevice extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            planId: '',
            deviceNo: 1,
            buttonLoading: false,
            showModal: false,
            currentSubscriptionId: '',
            currentPackageId: '',
            contents: [],
            loading: true,
            selectArray: [],
            newPackageId: '',
            newPlanCodeNaira: '',
            newPlanCodeDollar: '',
            paystackSubscriptions: [],
            currentStartDate: new Date(),
            currentEndDate: new Date(),
        }
    }
    componentDidMount(){
        fetch(window.backendDomain+'/api/v1/profile/subscription', {
            method: 'GET',
            headers: {
            'Content-type': 'application/json',
            'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json.data);
            if (json.status === 'OK' && json.data != null) {
                this.setState({currentSubscriptionId: json.data._id});
                this.setState({currentPackageId: json.data.package_id});
                this.setState({currentStartDate: json.data.start_date});
                this.setState({currentEndDate: json.data.end_date});
                fetch(window.backendDomain+'/api/v1/packages/id/'+json.data.package_id, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_package => {
                    //console.log(json_package);
                    if (json_package.status === 'OK') {
                        this.setState({contents: json_package.data});
                        this.setState({planId: json_package.data.duration});
                        var selectArrayTem = [];
                        for(var i=1; i <= 5-json_package.data.device; i++){
                            selectArrayTem.push({value: i, item: i+' device(s)'});
                        }
                        this.setState({selectArray: selectArrayTem});
                        this.setState({loading: false});
                    } else {
                        
                    }
                });
            } else {
                
            }
        });

        fetch('https://api.paystack.co/customer/'+window.contentsUser.user.email, {
            method: 'GET',
            //body: JSON.stringify(data),
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer '+window.paystackSecretKey
            }
        })
        .then(response => response.json())
        .then(json_sub_paystack => {
            //console.log(json_sub_paystack);
            this.setState({paystackSubscriptions: json_sub_paystack.data.subscriptions});
        });
    };

    render() { 
        const handleSelectChange = (value) => {
            this.setState({deviceNo: value});
            var newTag = this.state.contents.duration+"_"+(parseInt(value)+parseInt(this.state.contents.device));
            fetch(window.backendDomain+'/api/v1/packages/view/'+newTag, {
                method: 'GET',
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (json.status === 'OK') {
                    this.setState({newPackageId: json.data[0]._id});
                    this.setState({newPlanCodeNaira: json.data[0].paystack_plan_code_naira});
                    this.setState({newPlanCodeDollar: json.data[0].paystack_plan_code_dollar});
                } else {
                    Swal.fire({  
                        title: json.title,  
                        type: 'error',
                        icon: 'error',   
                        text: json.message,  
                    });
                }
            });
        }
        // const startDate = new Date()
        // var date = new Date();
        // const endDate = new Date(date.setMonth(date.getMonth()+this.state.contents.duration));
        //console.log(endDate)
        const handleClose = () => this.setState({showModal: false});
        
        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        switch (this.state.buttonLoading) {
            case false:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
            break;
            case true:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
            break;
            default:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        } 
        const handleSubmit = () => {
            this.setState({buttonLoading: true});
            const deviceNo = this.state.deviceNo;
            if(deviceNo === ""){
              Swal.fire({  
                title: 'ERROR',  
                icon: 'error',   
                text: 'Please, select device!',  
              });
              this.setState({buttonLoading: false});
            } else {
                this.setState({buttonLoading: false});
                this.setState({showModal: true});
            }
        } 
        
        const configNaira = {
            reference: (new Date()).getTime().toString()+Math.floor((Math.random() * 1000000000) + 1),
            email: window.contentsUser.user.email,
            //amount: this.state.deviceNo * 500 * 100,
            amount: ((parseFloat(this.state.deviceNo) * 500) + ((parseFloat(this.state.deviceNo) * 500) * 0.075)).toFixed(2) * 100,
            currency: 'NGN',
            metadata: {
                name: window.contentsUser.user.name,
                phone: window.contentsUser.user.phone,
            },
            publicKey: window.paystackPublicKey,
        };
        const onSuccessNaira = (reference) => {
            //console.log(reference);
            for(var i = 0; i < this.state.paystackSubscriptions.length; i++){
                const data  = {
                    code: this.state.paystackSubscriptions[i]['subscription_code'],
                    token: this.state.paystackSubscriptions[i]['email_token']
                }
                fetch('https://api.paystack.co/subscription/disable', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_sub_disable => {
                    console.log(json_sub_disable);
                });
            }
            // const startDate = new Date()
            // var date = new Date();
            // const endDate = new Date(date.setMonth(date.getMonth()+1));

            const dataSub  = {
                packageId: this.state.newPackageId,
                startDate: this.state.currentStartDate,
                endDate: this.state.currentEndDate
            }
            fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                method: 'POST',
                body: JSON.stringify(dataSub),
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                //console.log(json_sub);
                if (json_sub.status === 'OK') {
                    this.setState({showModal: false});
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'success',   
                        text: json_sub.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'error',   
                        text: json_sub.message,  
                    });
                }
            });
            
            /*
            const data  = {
                customer: window.contentsUser.user.email,
                plan: this.state.contents.paystack_plan_code_naira,
                start_date: endDate
            }
            fetch('https://api.paystack.co/subscription', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+window.paystackSecretKey
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                const dataSub  = {
                    packageId: this.state.newPackageId,
                    startDate: startDate,
                    endDate: endDate
                }
                fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                    method: 'POST',
                    body: JSON.stringify(dataSub),
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub => {
                    //console.log(json_sub);
                    if (json_sub.status === 'OK') {
                        this.setState({showModal: false});
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'success',   
                            text: json_sub.message,  
                        });
                    } else {
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'error',   
                            text: json_sub.message,  
                        });
                    }
                });
            });
            */
        };
        const onCloseNaira = () => {
            //console.log('closed')
        }
        const PaystackHookNaira = () => {
            const initializePayment = usePaystackPayment(configNaira);
            return (
                <div>
                    <Button size="lg" variant="primary" type="submit" onClick={() => {
                        initializePayment(onSuccessNaira, onCloseNaira)
                    }} className="w-100">Naira Card</Button>
                </div>
            );
        };

        const configDollar = {
            reference: (new Date()).getTime().toString()+Math.floor((Math.random() * 1000000000) + 1),
            email: window.contentsUser.user.email,
            //amount: this.state.deviceNo * 100,
            amount: (parseFloat(this.state.deviceNo) + (parseFloat(this.state.deviceNo) * 0.075)).toFixed(2) * 100,
            currency: 'USD',
            metadata: {
                name: window.contentsUser.user.name,
                phone: window.contentsUser.user.phone,
            },
            publicKey: window.paystackPublicKey,
        };
        const onSuccessDollar = (reference) => {
            //console.log(reference);
            for(var i = 0; i < this.state.paystackSubscriptions.length; i++){
                const data  = {
                    code: this.state.paystackSubscriptions[i]['subscription_code'],
                    token: this.state.paystackSubscriptions[i]['email_token']
                }
                fetch('https://api.paystack.co/subscription/disable', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_sub_disable => {
                    //console.log(json_sub_disable);
                });
            }
            const dataSub  = {
                packageId: this.state.newPackageId,
                startDate: this.state.currentStartDate,
                endDate: this.state.currentEndDate
            }
            fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                method: 'POST',
                body: JSON.stringify(dataSub),
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                //console.log(json_sub);
                if (json_sub.status === 'OK') {
                    this.setState({showModal: false});
                    Swal.fire({  
                        title: json_sub.title,  
                        type: 'success',
                        icon: 'success',   
                        text: json_sub.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json_sub.title,  
                        type: 'error',
                        icon: 'error',   
                        text: json_sub.message,  
                    });
                }
            });
            /*
            const data  = {
                customer: window.contentsUser.user.email,
                plan: this.state.contents.paystack_plan_code_dollar,
                start_date: endDate
            }
            fetch('https://api.paystack.co/subscription', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+window.paystackSecretKey
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                const dataSub  = {
                    packageId: this.state.newPackageId,
                    startDate: startDate,
                    endDate: endDate
                }
                fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                    method: 'POST',
                    body: JSON.stringify(dataSub),
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub => {
                    //console.log(json_sub);
                    if (json_sub.status === 'OK') {
                        this.setState({showModal: false});
                        Swal.fire({  
                            title: json_sub.title,  
                            type: 'success',
                            icon: 'success',   
                            text: json_sub.message,  
                        });
                    } else {
                        Swal.fire({  
                            title: json_sub.title,  
                            type: 'error',
                            icon: 'error',   
                            text: json_sub.message,  
                        });
                    }
                });
            });
            */
        };
        const onCloseDollar = () => {
            //console.log('closed dollar')
        }
        const PaystackHookDollar = () => {
            const initializePayment = usePaystackPayment(configDollar);
            return (
                <div>
                    <Button size="lg" variant="primary" type="submit" onClick={() => {
                        initializePayment(onSuccessDollar, onCloseDollar)
                    }} className="w-100">Dollar Card</Button>
                </div>
            );
        };
        
        return (
            <div>
                <Modal show={this.state.showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Additional Device - Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <center>
                            <p><b>Amount:</b> &#8358;{((parseFloat(this.state.deviceNo) * 500) + ((parseFloat(this.state.deviceNo) * 500) * 0.075)).toFixed(2)} (${(parseFloat(this.state.deviceNo) + (parseFloat(this.state.deviceNo) * 0.075)).toFixed(2)})</p>
                            <hr />
                            <h5>Please, choose a payment currency</h5>
                            <p>Credit or Debit Card <img src={cardImage} alt="Payment Options" width="150" /></p>
                            <div className="row">
                                <div className="col-6">
                                    <PaystackHookNaira />
                                </div>
                                <div className="col-6">
                                    <PaystackHookDollar />
                                </div>
                            </div>
                            
                            <br />
                            <div className="row">
                                <div className="col-12">
                                        <p>By clicking on the payment button, you agreed with <a href="https://oyawatch.com/terms-of-use/" target="_blank" rel="noreferrer noopener" >Terms and Conditions</a>, <a href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="https://oyawatch.com/cookie-policy/" target="_blank" rel="noreferrer noopener">Cookie Policy</a></p>
                                        <center><p>Secure Server <i className="fa fa-lock" style={{color: 'green'}}></i></p></center>
                                    </div>
                            </div>
                        </center>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-div-2">
                                            <center><h4>Add Additional Device</h4></center>
                                            <hr />
                                            {
                                                this.state.currentSubscriptionId === "" && this.state.loading === false?
                                                    <center>
                                                        <h6>You don't have any subscription at the moment, kindly subscribe!</h6>
                                                    </center>
                                                :
                                                <>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Devices</Form.Label>
                                                        {
                                                            this.state.loading === false?
                                                            <select className="form-control" onChange={(e) => handleSelectChange(e.target.value)}>
                                                                <option value="">Select device</option>
                                                                {
                                                                    this.state.selectArray.map((item) => (
                                                                        <option key={item.value} value={item.value}>{item.item}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            :
                                                            <select className="form-control">
                                                                <option value="">Loading device</option>
                                                            </select>
                                                        }
                                                </Form.Group>
                                                {submitButton}
                                                </>
                                            }
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default AdditionalDevice
