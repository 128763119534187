import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

class MySubscriptions extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            buttonLoading: false,
            contents: [],
            loading: true,
        }
    }
    componentDidMount(){
        fetch(window.backendDomain+'/api/v1/profile/subscriptions', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
                this.setState({contents: json.data});
                this.setState({loading: false});
            } else {
                
            }
        });
    }

    render() { 
        var todayDate = new Date();
        var todayYear = todayDate.getFullYear();
        var todayMonth, todayDay;
        //var todaySecond;
		if ((todayDate.getMonth()+1) < 10) {
			todayMonth = "0"+(todayDate.getMonth()+1);
		} else {
			todayMonth = todayDate.getMonth()+1;
		}
		if ((todayDate.getDate()) < 10) {
			todayDay = "0"+todayDate.getDate();
		} else {
		    todayDay = todayDate.getDate();
		}
        var today = todayYear+"-"+todayMonth+"-"+todayDay+"T23:00:00.000Z";
        //console.log(this.state.contents);
        return (
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                       <center>
                                            <h4>My Subscripitons</h4>
                                        </center>
                                        <hr />
                                        { this.state.loading ?
                                            <div className="row">
                                                <div className="col-12">
                                                    <center><Loader /></center>
                                                </div>
                                            </div>
                                            :
                                            <>
                                            <table border="1px" rule="all" cellPadding="5px" width="100%">
                                                <tr>
                                                    <th>Package</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                {
                                                    this.state.contents.map((item) => (
                                                        <tr key={item[0].subscriptionId}>
                                                            <td>{item[1].name}</td>
                                                            <td>{(item[0].startDate).substr(0, 10)}</td>
                                                            <td>{(item[0].endDate).substr(0, 10)}</td>
                                                            <td>
                                                                {
                                                                    item[0].status === 0 || item[0].endDate < today ?
                                                                        <div style={{backgroundColor: '#D12B1E', padding: 2, borderRadius: 20, width: '100%', textAlign: 'center'}}>Expired</div>
                                                                    :
                                                                        <div style={{backgroundColor: 'green', padding: 2, borderRadius: 20, width: '100%', textAlign: 'center'}}>Active</div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item[0].cancel === 0 ?
                                                                        <Link to={`/subscription/cancel/${item[0].subscriptionId}`}>
                                                                            <Button size="sm" variant="primary" type="submit" className="w-100" >Cancel</Button>
                                                                        </Link>
                                                                    :
                                                                    <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default MySubscriptions
