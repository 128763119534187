import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Swal from "sweetalert2";
import i18n from 'i18n-js';

import Header from "../components/HeaderOut";
import Footer from "../components/Footer";

import loadingImage from '../images/loader.gif';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState(localStorage.getItem('visitor'));
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    // componentDidMount equivalent code goes here
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    const emailLowerCase = email?.toLowerCase();
    const phoneWithPlus = "+" + phone;

    let analyticData;
    await fetch('http://ip-api.com/json', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        analyticData = {
          country_name: response.country,
          country_code: response.countryCode,
          state_name: response.regionName,
          state_code: response.region,
          city_name: response.city,
          latitude: response.lat,
          longitude: response.lon,
          isp: response.isp,
          device: 'web',
        }
      });

    if (name === "" || email === "" || phone === "" || password === "" || confirmPassword === "") {
      Swal.fire({
        title: 'ERROR',
        type: 'error',
        icon: 'error',
        text: 'All fields are required!',
      });
      setButtonLoading(false);
    } else {
      const data = {
        name: name,
        email: emailLowerCase,
        phone: phoneWithPlus,
        country: country,
        password: password,
        confirmPassword: confirmPassword,
        analyticData: analyticData
      }

      fetch(window.backendDomain + '/api/v1/auth/register', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(json => {
          if (json.status === 'OK') {
            Swal.fire({
              title: json.title,
              icon: 'success',
              text: (json.message) + ". You can now login, check your inbox, promotions, social, spam, or junk for the activation mail.",
            }).then(function () {
              window.location = "/#/login";
            });
          } else {
            Swal.fire({
              title: json.title,
              icon: 'error',
              text: json.message,
            });
          }
          setButtonLoading(false);
        });
    }
  }

  let submitButton = (
    <Button size="lg" variant="primary" type="submit" className="w-100" onClick={handleSubmit}>
      {i18n.t('17')}
    </Button>
  );

  switch (buttonLoading) {
    case false:
      submitButton = (
        <Button size="lg" variant="primary" type="submit" className="w-100" onClick={handleSubmit}>
          {i18n.t('17')}
        </Button>
      );
      break;
    case true:
      submitButton = (
        <Button size="lg" variant="primary" type="submit" className="w-100 disabled">
          <img src={loadingImage} alt="LoadingImage" style={{ width: '20px' }} />
        </Button>
      );
      break;
    default:
      submitButton = (
        <Button size="lg" variant="primary" type="submit" className="w-100" onClick={handleSubmit}>
          {i18n.t('17')}
        </Button>
      );
  }

  return (
    <div>
      <Header />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-div">
                <center><h4>Register</h4></center>
                <hr />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{i18n.t('14')}</Form.Label>
                  <Form.Control size="lg" type="email" placeholder={i18n.t('14')} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{i18n.t('1')}</Form.Label>
                  <Form.Control size="lg" type="email" placeholder={i18n.t('11')} onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{i18n.t('52')}</Form.Label>
                  <PhoneInput
                    country={country ? country?.toLowerCase() : 'ng'}
                    value={phone}
                    placeholder={i18n.t('52')}
                    enableSearch={true}
                    containerStyle={{ width: '100%', color: 'black' }}
                    preferredCountries={['ng', 'us', 'gb']}
                    onChange={(e) => setPhone(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>{i18n.t('2')}</Form.Label>
                  <Form.Control size="lg" type="password" placeholder={i18n.t('2')} onChange={(e) => setPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>{i18n.t('15')}</Form.Label>
                  <Form.Control size="lg" type="password" placeholder={i18n.t('15')} onChange={(e) => setConfirmPassword(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p>{i18n.t('16')} <Link to="/terms" className="link"><u>{i18n.t('45')}</u></Link></p>
                </Form.Group>
                {submitButton}
                <Form.Group className="mt-3">
                  <div className="row">
                    <div className="col-12">
                      <center>
                        <Link to="/login" className="link"><u>{i18n.t('18')}</u></Link>
                      </center>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
