import React, { Component } from 'react';

class Footer extends Component {
    render() {   
        const d = new Date();
        let year = d.getFullYear();
      return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        
                    </div>
                    <div className="col-md-4">
                        <center>
                            <div className="col-12">
                                <b><a href="https://intradot.com.ng" className="link">INTRADOT LTD</a> <i className="fa fa-copyright"></i> Copyright {year}</b> 
                            </div>
                        </center>
                    </div>
                    <div className="col-md-4">
                        
                    </div>
                </div>
            </div>
        </div>
      );
    }        
}

export default Footer
