import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Loader from "../components/Loader";
import Swal from "sweetalert2"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Header from "../components/Header";
import Footer from "../components/Footer";

class MyAccount extends Component {
    constructor(props) {
      super(props)

      this.state = {
        showModal: false,
        contents: [],
        loading: true,
      }
    }
        
    componentDidMount(){
        fetch(window.backendDomain+'/api/v1/profile/view', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'auth-token': localStorage.getItem('token')
            }
          })
          .then(response => response.json())
          .then(json => {
            if (json.status === 'OK') {
              this.setState({contents: json.data});
              this.setState({loading: false});

              this.setState({email: json.data.email});
              this.setState({name: json.data.name});
              this.setState({phone: json.data.phone});
              this.setState({country: json.data.country});
              this.setState({selectedLanguage: json.data.selected_language});
            } else {
                localStorage.removeItem("token");
                window.location="/login";
            }
          });
    }
    
    render() { 
      const handleClose = () => this.setState({showModal: false}); 

      const handleSubmit = () => {
        const data = {}
        fetch(window.backendDomain+'/api/v1/profile/remove-device-all', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-type': 'application/json',
              'auth-token': localStorage.getItem('token')
          }
        })
        .then(response => response.json())
        .then(json => {
          //console.log(json);
          if (json.status === 'OK') {
            Swal.fire({  
              position: 'center',  
              icon: 'success',  
              title: json.title,  
              text: json.message, 
              showConfirmButton: false,  
              timer: 4000  
            });
            this.setState({showModal: false});
            localStorage.removeItem("token");
            window.location="/#/login";
          } else {
            Swal.fire({  
              title: json.title,  
              type: 'error',
              icon: 'error',   
              text: json.message,  
            });
          }
        });
      }  

      return (
        <div>
            <Modal show={this.state.showModal} onHide={handleClose}>
              <Modal.Body>
                <center>
                  <h5 className="mt-5">Are you sure you want to logout from all devices including this device?</h5>
                  <div className="row mt-5 mb-5">
                    <div className="col-6">
                      <Button variant="primary" className="w-100" onClick={() => handleSubmit()}>
                        Yes
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button variant="primary" className="w-100" onClick={handleClose}>
                        No
                      </Button>
                    </div>
                  </div>
                </center>
              </Modal.Body>
            </Modal>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-1 pb-4">
                            <div className="row">
                                <div className="col-12">
                                    <h3>My Account</h3>
                                    <br />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <h4>Personal Details</h4>
                                    { this.state.loading ?
                                      <div className="row">
                                          <div className="col-12">
                                              <center><Loader /></center>
                                          </div>
                                      </div>
                                      :
                                      <>
                                        <span className="link pt-2"><h6>{this.state.contents.user.name}</h6></span>
                                        <span className="link pt-1"><h6>{this.state.contents.user.email}</h6></span>
                                        <span className="link pt-1"><h6>{this.state.contents.user.phone}</h6></span>
                                        <span className="link pt-1"><h6>{this.state.contents.user.country}</h6></span>
                                      </>
                                    }
                                </div>
                                <div className="col-md-6 mt-3">
                                    <h4>Languages</h4>
                                    <Link className="link active-link pt-2"><h6>English</h6></Link>
                                    <Link className="link pt-"><h6>Francais</h6></Link>
                                    <Link className="link pt-1"><h6>Hausa</h6></Link>
                                    <Link className="link pt-1"><h6>Igbo</h6></Link>
                                    <Link className="link pt-1"><h6>Yoruba</h6></Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <Link to="account/change-password"><button className="btn btn-primary w-100 mt-3">Change Password</button></Link>
                                </div>
                                <div className="col-md-3">
                                  <button onClick={() => this.setState({showModal: true})} className="btn btn-primary w-100 mt-3">Logout of all devices</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default MyAccount
