import React, { useState, useEffect, useRef } from "react";
import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from 'react-player'
import { Link, useHistory } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const VideoPlayer = ({
    videoUrl,
    adsData,
    handleAdsUserAction,
    calculateDuration,
}) => {
    const history = useHistory();
    const playerRef = useRef();
    const userId = localStorage.getItem('userId');
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [showControls, setShowControls] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [shouldPlay, setShouldPlay] = useState(true);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isLive, setIsLive] = useState(true);
    const [streamLoaded, setStreamLoaded] = useState(false);
    const [currentAdsIndex, setCurrentAdsIndex] = useState(0);
    const [showAds, setShowAds] = useState(true);
    const [lastAdsTimestamp, setLastAdsTimestamp] = useState(new Date().valueOf());
    const [buffering, setBuffering] = useState(true);

    useEffect(() => {
        if(videoUrl.endsWith("playlist.m3u8")){
            setIsLive(true);
        } else {
            setIsLive(false);
            setCurrentTime(0);
            setDuration(3600);
        }
    }, []);

    const onSliderValueChange = (value) => {
        if(value >= 0){
            setCurrentTime(Math.floor(value));
            playerRef.current.seekTo(Math.floor(value));
        }
    };

    const toHoursAndMinutes = (totalSeconds) => {
        const totalMinutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours < 10 ? '0'+hours : hours}:${minutes < 10 ? '0'+minutes : minutes}:${seconds < 10 ? '0'+seconds : seconds}`;
    }

    function getCurrentDimension(){
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
      const updateDimension = () => {
        setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
      
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
    }, [screenSize]);

    useEffect(() => {
        if(showControls){
            const timer = setTimeout(async () => {
                setShowControls(false);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [showControls]);

    const handleMouseEnter = () => {
        setShowControls(true);
        const currentTimestamp = new Date().valueOf();
        if(shouldPlay && !showAds && adsData.length > 0 && currentTimestamp - lastAdsTimestamp > 60000){
            const nextIndex = currentAdsIndex + 1;
            if(nextIndex < adsData.length){
                const dataAds = adsData[nextIndex];
                const currentDateToday = new Date(); 
                let todayYear = currentDateToday.getFullYear();
                let todayMonth = "";
                let todayDay = "";
                if ((currentDateToday.getMonth()+1) < 10) {
                    todayMonth = "0"+(currentDateToday.getMonth()+1);
                } else {
                    todayMonth = currentDateToday.getMonth()+1;
                }
                if ((currentDateToday.getDate()) < 10) {
                    todayDay = "0"+currentDateToday.getDate();
                } else {
                    todayDay = currentDateToday.getDate();
                }
                let dateNowAds = todayYear+"-"+todayMonth+"-"+todayDay;
                let timeNowAds = currentDateToday.getHours() + ":" + currentDateToday.getMinutes();
                //if(dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)){
                    //if(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to){
                        const totalShows = adsData[nextIndex].users_views.filter(x => x == userId).length;
                        //if(adsData[nextIndex].num_shows < adsData[nextIndex].max_show_total && totalShows < adsData[nextIndex].max_show_user){
                            setCurrentAdsIndex(nextIndex);
                            setShowAds(true);
                            handleAdsUserAction('view', adsData[nextIndex]._id)
                        //}
                    //}
                //}
            } else {
                const dataAds = adsData[0];
                const currentDateToday = new Date(); 
                let todayYear = currentDateToday.getFullYear();
                let todayMonth = "";
                let todayDay = "";
                if ((currentDateToday.getMonth()+1) < 10) {
                    todayMonth = "0"+(currentDateToday.getMonth()+1);
                } else {
                    todayMonth = currentDateToday.getMonth()+1;
                }
                if ((currentDateToday.getDate()) < 10) {
                    todayDay = "0"+currentDateToday.getDate();
                } else {
                    todayDay = currentDateToday.getDate();
                }
                let dateNowAds = todayYear+"-"+todayMonth+"-"+todayDay;
                let timeNowAds = currentDateToday.getHours() + ":" + currentDateToday.getMinutes();
                //if(dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)){
                    //if(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to){
                        const totalShows = adsData[0].users_views.filter(x => x == userId).length;
                        //if(adsData[0].num_shows < adsData[0].max_show_total && totalShows < adsData[0].max_show_user){
                            setCurrentAdsIndex(0);
                            setShowAds(true);
                            handleAdsUserAction('view', adsData[0]._id);
                        //}
                    //}
                //}
            }
        }
    };
    
    const handleMouseLeave = () => {
        setShowControls(false);
        const currentTimestamp = new Date().valueOf();
        if(shouldPlay && !showAds && adsData.length > 0 && currentTimestamp - lastAdsTimestamp > 60000){
            const nextIndex = currentAdsIndex + 1;
            if(nextIndex < adsData.length){
                const dataAds = adsData[nextIndex];
                const currentDateToday = new Date(); 
                let todayYear = currentDateToday.getFullYear();
                let todayMonth = "";
                let todayDay = "";
                if ((currentDateToday.getMonth()+1) < 10) {
                    todayMonth = "0"+(currentDateToday.getMonth()+1);
                } else {
                    todayMonth = currentDateToday.getMonth()+1;
                }
                if ((currentDateToday.getDate()) < 10) {
                    todayDay = "0"+currentDateToday.getDate();
                } else {
                    todayDay = currentDateToday.getDate();
                }
                let dateNowAds = todayYear+"-"+todayMonth+"-"+todayDay;
                let timeNowAds = currentDateToday.getHours() + ":" + currentDateToday.getMinutes();
                //if(dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)){
                    //if(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to){
                        const totalShows = adsData[nextIndex].users_views.filter(x => x == userId).length;
                        //if(adsData[nextIndex].num_shows < adsData[nextIndex].max_show_total && totalShows < adsData[nextIndex].max_show_user){
                            setCurrentAdsIndex(nextIndex);
                            setShowAds(true);
                            handleAdsUserAction('view', adsData[nextIndex]._id)
                        //}
                    //}
                //}
            } else {
                const dataAds = adsData[0];
                const currentDateToday = new Date(); 
                let todayYear = currentDateToday.getFullYear();
                let todayMonth = "";
                let todayDay = "";
                if ((currentDateToday.getMonth()+1) < 10) {
                    todayMonth = "0"+(currentDateToday.getMonth()+1);
                } else {
                    todayMonth = currentDateToday.getMonth()+1;
                }
                if ((currentDateToday.getDate()) < 10) {
                    todayDay = "0"+currentDateToday.getDate();
                } else {
                    todayDay = currentDateToday.getDate();
                }
                let dateNowAds = todayYear+"-"+todayMonth+"-"+todayDay;
                let timeNowAds = currentDateToday.getHours() + ":" + currentDateToday.getMinutes();
                //if(dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)){
                    //if(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to){
                        const totalShows = adsData[0].users_views.filter(x => x == userId).length;
                        //if(adsData[0].num_shows < adsData[0].max_show_total && totalShows < adsData[0].max_show_user){
                            setCurrentAdsIndex(0);
                            setShowAds(true);
                            handleAdsUserAction('view', adsData[0]._id);
                        //}
                    //}
                //}
            }
        }
    };

    const handleMouseMove = () => {
        setShowControls(true);
        const currentTimestamp = new Date().valueOf();
        if(shouldPlay && !showAds && adsData.length > 0 && currentTimestamp - lastAdsTimestamp > 60000){
            const nextIndex = currentAdsIndex + 1;
            if(nextIndex < adsData.length){
                const dataAds = adsData[nextIndex];
                const currentDateToday = new Date(); 
                let todayYear = currentDateToday.getFullYear();
                let todayMonth = "";
                let todayDay = "";
                if ((currentDateToday.getMonth()+1) < 10) {
                    todayMonth = "0"+(currentDateToday.getMonth()+1);
                } else {
                    todayMonth = currentDateToday.getMonth()+1;
                }
                if ((currentDateToday.getDate()) < 10) {
                    todayDay = "0"+currentDateToday.getDate();
                } else {
                    todayDay = currentDateToday.getDate();
                }
                let dateNowAds = todayYear+"-"+todayMonth+"-"+todayDay;
                let timeNowAds = currentDateToday.getHours() + ":" + currentDateToday.getMinutes();
                //if(dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)){
                    //if(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to){
                        const totalShows = adsData[nextIndex].users_views.filter(x => x == userId).length;
                        //if(adsData[nextIndex].num_shows < adsData[nextIndex].max_show_total && totalShows < adsData[nextIndex].max_show_user){
                            setCurrentAdsIndex(nextIndex);
                            setShowAds(true);
                            handleAdsUserAction('view', adsData[nextIndex]._id)
                        //}
                    //}
                //}
            } else {
                const dataAds = adsData[0];
                const currentDateToday = new Date(); 
                let todayYear = currentDateToday.getFullYear();
                let todayMonth = "";
                let todayDay = "";
                if ((currentDateToday.getMonth()+1) < 10) {
                    todayMonth = "0"+(currentDateToday.getMonth()+1);
                } else {
                    todayMonth = currentDateToday.getMonth()+1;
                }
                if ((currentDateToday.getDate()) < 10) {
                    todayDay = "0"+currentDateToday.getDate();
                } else {
                    todayDay = currentDateToday.getDate();
                }
                let dateNowAds = todayYear+"-"+todayMonth+"-"+todayDay;
                let timeNowAds = currentDateToday.getHours() + ":" + currentDateToday.getMinutes();
                //if(dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)){
                    //if(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to){
                        const totalShows = adsData[0].users_views.filter(x => x == userId).length;
                        //if(adsData[0].num_shows < adsData[0].max_show_total && totalShows < adsData[0].max_show_user){
                            setCurrentAdsIndex(0);
                            setShowAds(true);
                            handleAdsUserAction('view', adsData[0]._id);
                        //}
                    //}
                //}
            }
        }
    };

    useEffect(() => {
        if(adsData.length > 0){
            const timer = setTimeout(async () => {
                if(showAds === true && streamLoaded){
                    setShowAds(false);
                    setLastAdsTimestamp(new Date().valueOf());
                }
            }, adsData[currentAdsIndex].duration * 1000);
            return () => clearTimeout(timer);
        }
    }, [showAds, adsData, streamLoaded]);

    const [isLooping, setIsLooping] = useState(false);
    const [totalVideoDuration, setTotalVideoDuration] = useState(0);
    const [loadedSeconds, setLoadedSeconds] = useState(0);

    useEffect(() => {
        if(currentTime > totalVideoDuration){
            setTotalVideoDuration(Math.round(currentTime));
        }
    }, [currentTime]);

    useEffect(() => {
        //console.log(totalVideoDuration, loadedSeconds);
        if(totalVideoDuration === loadedSeconds && totalVideoDuration > 0){
            setIsLooping(true);
        }
    }, [totalVideoDuration, loadedSeconds]);
    
    return(
        <div
            style={{ position: 'relative', height: '100vh' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            >
            <ReactPlayer 
                url={videoUrl}
                playing={shouldPlay}
                controls={false}
                muted={isMuted}
                onDuration={(duration) => {
                    setDuration(duration);
                    setTotalVideoDuration(Math.round(duration));
                }}
                onProgress={(progress) => {
                    setLoadedSeconds(Math.round(progress.loadedSeconds));
                    setCurrentTime(progress.playedSeconds);
                    if(isLive){
                        setDuration(progress.loadedSeconds); 
                    }
                }}
                onReady={() => {
                    setStreamLoaded(true);
                    setShouldPlay(true);
                    setBuffering(false);
                }}
                onBuffer={() => setBuffering(true)}
                onBufferEnd={() => setBuffering(false)}
                width={screenSize?.width - 10}
                height={screenSize?.height - 10}
                style={{boxSizing: 'border-box', overflow: 'hidden'}}
                ref={playerRef}
            />
            {
                buffering &&
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', /* semi-transparent background */
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div className="loading-spinner"></div>
                </div>
            }
            {showControls &&
                <div style={{
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    border: '0px solid red', 
                    width: '100%', 
                    height: '100vh'
                }}>
                    <div style={{
                        position: 'absolute', 
                        top: '5vh', 
                        left: 0, 
                        width: '100%', 
                        height: 'auto', 
                        padding: '0 5%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        border: '0px solid orange'
                    }}>
                        <button 
                            style={{
                                background: 'transparent', 
                                border: 0,
                                fontSize: '22pt',
                                color: 'white',
                            }} 
                            onClick={() => {
                                history.goBack();
                                calculateDuration(new Date());
                            }}
                            className="playerCloseBtn0" >
                            <i className="fa fa-times"></i>
                        </button>
                        <button
                            onClick={() => setIsMuted(!isMuted)}
                            style={{
                                background: 'transparent', 
                                border: 0,
                                fontSize: '22pt',
                                color: 'white',
                            }} >
                            {
                                isMuted ? 
                                <i class="fas">&#xf6a9;</i>
                                :
                                <i class="fas">&#xf028;</i>
                            }
                        </button>
                    </div>
                    { !buffering &&
                    <div style={{
                        position: 'absolute', 
                        top: '45vh', 
                        left: 0, 
                        width: '100%', 
                        height: '10vh', 
                        padding: '0 5%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '20px',
                    }}>
                        <div
                            style={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                            >
                            <button
                                onClick={() => { 
                                    onSliderValueChange(currentTime - 10);
                                }}
                                style={{
                                    background: 'transparent', 
                                    border: 0,
                                    fontSize: '22pt',
                                    color: 'white',
                                }} >
                                <i class="fa fa-backward"></i>
                            </button>
                            <button
                                onClick={() => { 
                                    setShouldPlay(!shouldPlay);
                                }}
                                style={{
                                    background: 'transparent', 
                                    border: 0,
                                    fontSize: '22pt',
                                    color: 'white',
                                }} >
                                {
                                    shouldPlay ?
                                        <i class="fa fa-pause"></i>
                                    :
                                        <i class="fa fa-play"></i>
                                }
                            </button>
                            <button
                                onClick={() => { 
                                    onSliderValueChange(currentTime + 10);
                                }}
                                style={{
                                    background: 'transparent', 
                                    border: 0,
                                    fontSize: '22pt',
                                    color: 'white',
                                }} >
                                <i class="fa fa-forward"></i>
                            </button>
                        </div>
                    </div>
                    }
                    <div style={{
                        position: 'absolute', 
                        bottom: '5vh', 
                        left: 0, 
                        width: '100%', 
                        height: 'auto', 
                        padding: '0 5%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '20px',
                        border: '0px solid orange',
                        backgroundColor: 'rgba(1, 1, 1, 0.5)',
                    }}>
                        <button
                            onClick={() => onSliderValueChange(0)}
                            style={{
                                borderWidth: 0,
                                borderColor: 'orange',
                                width: '100px',
                                height: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                bottom: 25,
                                backgroundColor: '#D12B1E',
                                borderRadius: 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white', 
                                fontWeight: 600, 
                                fontSize: 15
                            }}
                            >
                            {toHoursAndMinutes(currentTime)}
                        </button>
                        <Slider
                            min={0}
                            max={duration}
                            value={currentTime}
                            trackStyle={{
                                background: '#D12B1E'
                            }} 
                            railStyle={{
                                background: 'white'
                            }}
                            onChange={(value) => onSliderValueChange(value)}
                            style={{marginTop: '3px'}}
                        />
                        <button
                            onClick={() => onSliderValueChange(duration)}
                            style={{
                                borderWidth: 0,
                                borderColor: 'orange',
                                width: '100px',
                                height: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                bottom: 25,
                                backgroundColor: '#D12B1E',
                                borderRadius: 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white', 
                                fontWeight: 600, 
                                fontSize: 15
                            }}
                            >
                            {(isLive && !isLooping) ? <div style={{display: 'flex', flexDirection: 'row'}}><div className="live-dot"></div> Live</div> : toHoursAndMinutes(duration)}
                        </button>
                    </div>
                </div>
            }
            {
                showAds && adsData.length > 0 && 
                <div 
                  style={{
                    width: '100%', 
                    height: 'auto', 
                    zIndex: 99999,
                    position: 'fixed',
                    bottom: '30px',
                    left: 0,
                    border: '0px solid red',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#000'
                  }}>
                  <div style={{cursor: 'pointer'}} 
                    onClick={() => handleAdsUserAction('click', adsData[currentAdsIndex]._id, adsData[currentAdsIndex].action_url)}
                    >
                    <img src={adsData[currentAdsIndex].image} width={`${screenSize?.width - 100}px`} height={`${((screenSize?.width - 100) * adsData[currentAdsIndex].height)/(adsData[currentAdsIndex].width)}px`} />
                  </div>
                </div>
            }
        </div>
    )
}

export default VideoPlayer;