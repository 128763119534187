import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import ReactHlsPlayer from 'react-hls-player';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
import VideoPlayer from '../components/player/VideoPlayer';

const ChannelPlay = ({ match, history }) => {
  const [dateToday, setDateToday] = useState('');
  const [timeToday, setTimeToday] = useState('');
  const [dateYesterday, setDateYesterday] = useState('');
  const [dateTomorrow, setDateTomorrow] = useState('');
  const [contents, setContents] = useState([]);
  const [visitor, setVisitor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [block, setBlock] = useState(false);
  const [subBlock, setSubBlock] = useState(false);
  const [replay, setReplay] = useState(false);
  const [firstDate, setFirstDate] = useState(null);
  const [channelHasAds, setChannelHasAds] = useState(false);
  const [showAds, setShowAds] = useState(false);
  const [adsData, setAdsData] = useState([]);
  const [sub_block, setSub_block] = useState(false);
  const [adsDataDesktop, setAdsDataDesktop] = useState(null);

  useEffect(() => {
    let { tag } = match.params;
    fetch(window.backendDomain + '/api/v1/contents/channel/' + tag, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'auth-token': localStorage.getItem('token')
      }
    })
      .then(response => response.json())
      .then(json_contents => {
        console.log(json_contents);
        if (json_contents.status === 'OK') {
          setContents(json_contents.data);
          setLoading(false);
          setFirstDate(new Date());
          const adsSize = json_contents.data.adsData.length;
          if (adsSize > 0) {
            setAdsData(json_contents.data.adsData);
            //console.log(json_contents.data.adsData[0]);
            // const dataAds = json_contents.data.adsData[0];
            // const currentDateToday = new Date();
            // let todayYear = currentDateToday.getFullYear();
            // let todayMonth = (currentDateToday.getMonth() + 1).toString().padStart(2, '0');
            // let todayDay = currentDateToday.getDate().toString().padStart(2, '0');
            // let dateNowAds = `${todayYear}-${todayMonth}-${todayDay}`;
            // let timeNowAds = `${currentDateToday.getHours()}:${currentDateToday.getMinutes()}`;
            // if (dateNowAds >= dataAds.date_from.substring(0, 10) && dateNowAds <= dataAds.date_to.substring(0, 10)) {
            //   console.log(timeNowAds, dataAds.time_from);
            //   console.log(timeNowAds, dataAds.time_to);
            //   console.log(timeNowAds >= dataAds.time_from && dateNowAds <= dataAds.time_to)
            //   if (timeNowAds >= dataAds.time_from && timeNowAds <= dataAds.time_to) {
            //     setAdsData(json_contents.data.adsData[0]);
            //     setChannelHasAds(true);
            //     setShowAds(true);
            //     setAdsDataDesktop(json_contents.data.adsDataDesktop[json_contents.data.adsDataDesktop.length - 1])
            //   } else {
            //     setAdsData(null);
            //     setChannelHasAds(false);
            //   }
            // } else {
            //   setAdsData(null);
            //   setChannelHasAds(false);
            // }
          }
        }
      });

      
      const todayDate = new Date();
      const todayYear = todayDate.getFullYear();
      let todayMonth, todayDay, yesterdayDay, tomorrowDay, todayHour, todayMinute;
      
      if (todayDate.getMonth() + 1 < 10) {
        todayMonth = "0" + (todayDate.getMonth() + 1);
      } else {
        todayMonth = todayDate.getMonth() + 1;
      }
      
      if (todayDate.getDate() < 10) {
        todayDay = "0" + todayDate.getDate();
        yesterdayDay = "0" + (todayDate.getDate() - 1);
        tomorrowDay = "0" + (todayDate.getDate() + 1);
      } else {
        todayDay = todayDate.getDate();
        yesterdayDay = todayDate.getDate() - 1;
        tomorrowDay = todayDate.getDate() + 1;
      }
      
      if (todayDate.getHours() < 10) {
        todayHour = "0" + todayDate.getHours();
      } else {
        todayHour = todayDate.getHours();
      }
      
      if (todayDate.getMinutes() < 10) {
        todayMinute = "0" + todayDate.getMinutes();
      } else {
        todayMinute = todayDate.getMinutes();
      }
      
      const todayDateNew = todayDay + "-" + todayMonth + "-" + todayYear;
      const todayTimeNew = todayHour + ":" + todayMinute;
      
      setDateToday(todayDateNew);
      setTimeToday(todayTimeNew);
      
      const yesterdayDateNew = yesterdayDay + "-" + todayMonth + "-" + todayYear;
      setDateYesterday(yesterdayDateNew);
      
      const tomorrowDateNew = tomorrowDay + "-" + todayMonth + "-" + todayYear;
      setDateTomorrow(tomorrowDateNew);
      
    
    fetch(`${window.backendDomain}/api/v1/contents/channel/${tag}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'auth-token': localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(json_contents => {
          //console.log(json_contents);
          if (json_contents.status === 'OK') {
            setContents(json_contents.data);
      
            const geotype = json_contents.data.channelsList[0].geoblock;
            const countries = json_contents.data.channelsList[0].geoblock_countries;
            const country_code = localStorage.getItem('visitor');
            if (geotype === 1) {
              if (countries.includes(country_code)) {
                setBlock(true);
              }
            } else if (geotype === 2) {
              if (!countries.includes(country_code)) {
                setBlock(true);
              }
            } else {
      
            }
            setLoading(false);
          }
        });
    }, [match.params]);

    useEffect(() => {
      const subscriptions = contents?.userSubscriptions;
    
      var todayDate = new Date();
      var todayYear = todayDate.getFullYear();
      var todayMonth = "";
      var todayDay = "";
      if ((todayDate.getMonth() + 1) < 10) {
        todayMonth = "0" + (todayDate.getMonth() + 1);
      } else {
        todayMonth = todayDate.getMonth() + 1;
      }
      if ((todayDate.getDate()) < 10) {
        todayDay = "0" + todayDate.getDate();
      } else {
        todayDay = todayDate.getDate();
      }
      var dateNow = todayYear + "-" + todayMonth + "-" + todayDay + "T23:59:59.999Z";
      //console.log(dateNow);
      if (subscriptions?.length < 1) {
        setSub_block(true);
      } else {
        for (var i = 0; i < subscriptions?.length; i++) {
          var sub_end_date = subscriptions[i]['end_date'];
          if (dateNow > sub_end_date) {
            setSub_block(true);
          } else {
            setSub_block(false);
          }
        }
      }
    }, [contents]);

    useEffect(() => {
      if(adsData !== null){
        const timer = setTimeout(() => {
            if(showAds === true && channelHasAds === true){
                  setShowAds(false);
                  handleAdsUserAction('view', adsData?._id);
              }
          }, adsData.duration * 1000);
          return () => clearTimeout(timer);
      }
  }, [showAds, channelHasAds, adsData]);

    const handleAdsUserAction = async (actionType, adsId, url) => {
      const postAdsData  = {
        adsId: adsId,
        actionType: actionType
      }
      fetch(window.backendDomain+'/api/v1/ads/user-action', {
        method: 'POST',
        body: JSON.stringify(postAdsData),
        headers: {
          'Content-type': 'application/json',
          'auth-token': localStorage.getItem('token')
        }
      })
      .then(response => response.json())
      .then(json => {
        //console.log(json);
      });
      if(actionType === 'click' && url){
        window.open(url, '_blank');
      }
    }

    const calculateDuration = (date) => {
      let msDiff = new Date(date).getTime() - new Date(firstDate).getTime();    //Future date - current date
      let durationInMinutes = Math.round(msDiff / (1000 * 60));
      let channelId = contents?.channelsList[0]._id;
      //console.log(msDiff/(1000 * 60), durationInMinutes, channelId);
      const data = {
        channelId: channelId,
        duration: durationInMinutes
      }
      fetch(window.backendDomain + '/api/v1/analytics/channels/update', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json',
          'auth-token': localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(json => {
          //console.log(json);
        });
    }

    function getCurrentDimension(){
      return {
          width: window.innerWidth,
          height: window.innerHeight
      }
  }
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    useEffect(() => {
      const updateDimension = () => {
        setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
      
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
    }, [screenSize])

    // useEffect(() => {
    //   console.log(screenSize);
    //   console.log(adsDataDesktop?.width, adsDataDesktop?.height)
    // }, [screenSize, adsDataDesktop]);
    
return(
  <>
    {
      loading ?
        <div className="container">
          <Header />
          <br />
          <br />
          <br />
          <br />
          <center>
            <Loader />
          </center>
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </div>
        :
        <>
        {
          block === true ?
          <div className="container">
                  <Header />
                  <br />
                  <br />
                  <br />
                  <br />
                  <center>
                    <button className="btn btn-primary w-50 mt-5 disabled">Blocked for Your Region</button>
                  </center>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Footer />
                </div>
          :
          <>
          {
          sub_block === true ?
          <>
          { contents?.content[2].status === 1 && contents?.channelsList[0].paid === true ?
            <div className="container">
              <Header />
              <br />
              <br />
              <br />
              <br />
              <center>
                <Link to={'/subscription/purchase'}>
                  <button className="btn btn-primary w-50 mt-5">Click to Subscribe</button>
                </Link>
              </center>
              <br />
              <br />
              <br />
              <br />
              <Footer />
            </div>
          :
          <>
          <div className='playerContainer' style={{ height: '100vh' }}>
              <VideoPlayer
                videoUrl={contents?.channelsList[0].stream_url}
                calculateDuration={calculateDuration}
                handleAdsUserAction={handleAdsUserAction}
                adsData={adsData} 
              />
              {/* <ReactHlsPlayer
                //src={'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'}
                src={contents?.channelsList[0].stream_url}
                autoPlay={true}
                controls={!showAds}
                // width="100%"
                // height="100%"
                width={screenSize?.width - 10}
                height={screenSize?.height - 10}
                //onError={console.log('error')}
                onEnded={() => this.setState({ replay: true })}
                //onPause={() => calculateDuration(new Date())}
                //onPlay={() => this.setState({firstDate: new Date()})}
                style={{boxSizing: 'border-box', overflow: 'hidden'}}
              /> */}
              {
                showAds && 
                <div 
                  style={{
                    width: '100%', 
                    height: 'auto', 
                    zIndex: 99999,
                    position: 'fixed',
                    bottom: '30px',
                    left: 0,
                    border: '0px solid red',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#000'
                  }}>
                  <div style={{cursor: 'pointer'}} 
                    //onClick={() => handleAdsUserAction('click', adsData?._id)}
                    >
                    <img src={adsData?.image} width={`${screenSize?.width - 10}px`} height={`${((screenSize?.width - 10) * adsData?.height)/(adsData?.width)}px`} />
                  </div>
                </div>
              }
            </div>
          </>
          }
          </>
          :
          <>
            <div className='playerContainer' style={{ height: '100vh' }}>
            <VideoPlayer
              videoUrl={contents?.channelsList[0].stream_url}
              calculateDuration={calculateDuration}
              handleAdsUserAction={handleAdsUserAction}
              adsData={adsData}
            />
              {/* <ReactHlsPlayer
                //src={'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'}
                src={contents?.channelsList[0].stream_url}
                autoPlay={true}
                controls={!showAds}
                // width="100%"
                // height="100%"
                width={screenSize?.width - 10}
                height={screenSize?.height - 10}
                //onError={console.log('error')}
                onEnded={() => this.setState({ replay: true })}
              //onPause={() => calculateDuration(new Date())}
              //onPlay={() => this.setState({firstDate: new Date()})}
              /> */}
              {
                showAds && 
                <div 
                  style={{
                    width: '100%', 
                    height: 'auto', 
                    zIndex: 99999,
                    position: 'fixed',
                    bottom: '30px',
                    left: 0,
                    border: '0px solid red',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#000'
                  }}>
                  <div style={{cursor: 'pointer'}} 
                    //onClick={() => handleAdsUserAction('click', adsData?._id)}
                    >
                    <img src={adsData?.image} width={`${screenSize?.width - 10}px`} height={`${((screenSize?.width - 10) * adsData?.height)/(adsData?.width)}px`} />
                  </div>
                </div>
            }
            </div>
            </>
        }
          </>
        }
        </>
    }
  </>
);
    
}

export default ChannelPlay;