import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


window.paystackPublicKey = 'pk_live_a5176839be6cdd9bce23512fb757a0ca79427677';
window.paystackSecretKey = 'sk_live_4bc483228d2974845bd6d00ef058834aecdd2564';

// global variable
// developement
//window.backendDomain = 'http://192.168.0.101:5001';
//window.backendDomain = 'http://localhost:5001';
//window.backendUrl = 'http://localhost:5002';

//production
//window.backendDomain = 'http://151.106.32.53:5001';
//window.backendUrl = 'http://151.106.32.53:5002';
window.backendDomain = 'https://api.oyawatch.com';
window.backendUrl = 'https://api-cms.oyawatch.com';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
