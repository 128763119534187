import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from '../components/Loader';

class VodPlay extends Component {
    constructor(props) {
      super(props)
      this.state = {
        dateToday: '',
        timeToday: '',
        dateYesterday: '',
        dateTomorrow: '',
        contents: [],
        visitor: [],
        loading: true,
        block: false,
        sub_block: false,
      }
    }
        
    componentDidMount(){
      let { tag } = this.props.match.params;
      fetch(window.backendDomain+'/api/v1/contents/vod/'+tag, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'auth-token': localStorage.getItem('token')
        }
      })
      .then(response => response.json())
      .then(json_contents => {
        //console.log(json_contents);
        if (json_contents.status === 'OK') {
          this.setState({contents: json_contents.data});
          this.setState({loading: false});
        }
      });

      var todayDate = new Date();
		var todayYear = todayDate.getFullYear();
        var todayMonth, todayDay, yesterdayDay, tomorrowDay, todayHour, todayMinute;
        //var todaySecond;
		if ((todayDate.getMonth()+1) < 10) {
			todayMonth = "0"+(todayDate.getMonth()+1);
		} else {
			todayMonth = todayDate.getMonth()+1;
		}
		if ((todayDate.getDate()) < 10) {
			todayDay = "0"+todayDate.getDate();
            yesterdayDay = "0"+(todayDate.getDate()-1);
            tomorrowDay = "0"+(todayDate.getDate()+1);
		} else {
		    todayDay = todayDate.getDate();
            yesterdayDay = todayDate.getDate()-1;
            tomorrowDay = todayDate.getDate()+1;
		}
		if ((todayDate.getHours()) < 10) {
			todayHour = "0"+todayDate.getHours();
		} else {
			todayHour = todayDate.getHours();
		}
		if ((todayDate.getMinutes()) < 10) {
			todayMinute = "0"+todayDate.getMinutes();
		} else {
			todayMinute = todayDate.getMinutes();
		}
		if ((todayDate.getSeconds()) < 10) {
			//todaySecond = "0"+todayDate.getSeconds();
		} else {
			//todaySecond = todayDate.getSeconds();
		}
		var todayDateNew = todayDay+"-"+todayMonth+"-"+todayYear;
		var todayTimeNew = todayHour+":"+todayMinute;
        this.setState({dateToday: todayDateNew});
        this.setState({timeToday: todayTimeNew});

		var yesterdayDateNew = yesterdayDay+"-"+todayMonth+"-"+todayYear;
        this.setState({dateYesterday: yesterdayDateNew});
        var tomorrowDateNew = tomorrowDay+"-"+todayMonth+"-"+todayYear;
        this.setState({dateTomorrow: tomorrowDateNew});

        fetch(window.backendDomain+'/api/v1/contents/vod/'+tag, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json_contents => {
            console.log(json_contents);
            if (json_contents.status === 'OK') {
                this.setState({contents: json_contents.data});
                this.setState({loading: false});
            }
        });
    }
    
    render() { 
      const checkSubscription = (subscriptions) => {
        var sub_block = false;
        var todayDate = new Date();
        var todayYear = todayDate.getFullYear();
        var todayMonth = "";
        var todayDay = "";
        if ((todayDate.getMonth()+1) < 10) {
            todayMonth = "0"+(todayDate.getMonth()+1);
        } else {
            todayMonth = todayDate.getMonth()+1;
        }
        if ((todayDate.getDate()) < 10) {
            todayDay = "0"+todayDate.getDate();
        } else {
            todayDay = todayDate.getDate();
        }
        var dateNow = todayYear+"-"+todayMonth+"-"+todayDay+"T23:59:59.999Z";
        //console.log(dateNow);
        if (subscriptions.length < 1) {
            sub_block = true;
        } else {
            for(var i = 0; i < subscriptions.length; i++){
                var sub_end_date = subscriptions[i]['end_date'];
                if (dateNow > sub_end_date) {
                    sub_block = true;
                } else {
                    sub_block = false;
                }
            }
        }
        if (sub_block === true) {
            return  <>
            <Header />
            <button className="btn btn-primary w-100 mt-4 disabled">NO SUBSCRIPTION</button>
            <Footer />
            </>
        } else {
            return <>
            <Link to={`/vod/${this.props.match.params.tag}`} className="playerCloseBtn" ><i className="fa fa-times"></i></Link>
            {/* <ReactHlsPlayer
            src={this.state.contents.vodsList[0].stream_url}
            autoPlay={true}
            controls={true}
            width="100%"
            height="100%"
          /> */}
          <div className="container">
          <ReactPlayer url={this.state.contents.vodsList[0].stream_url} autoPlay={true}
            controls={true}
            width="100%"
            height="100%" />
            </div>
          </>
        }
    }  
      return (
        <div>
          { this.state.loading === true ?
            <div className="container">
              <div style={{marginTop: '20%'}}>
                <Loader />
              </div>
            </div>
          :
            <div className="container">
              { 
                checkSubscription(this.state.contents.userSubscriptions)
              }
            </div>
          }
        </div>
      );
    }        
}

export default VodPlay
