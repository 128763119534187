import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
 
// const responsive = {
//     superLargeDesktop: {
//         // the naming can be any, depends on you.
//         breakpoint: { max: 4000, min: 3000 },
//         items: 4
//     },
//     desktop: {
//         breakpoint: { max: 3000, min: 1024 },
//         items: 4
//     },
//     tablet: {
//         breakpoint: { max: 1024, min: 464 },
//         items: 2
//     },
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 2
//     }
// };



class VodPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vod: [],
            epg: [],
            previewLoading: true,
            epgLoading: true,
            dateToday: '',
            timeToday: '',
            dateYesterday: '',
            dateTomorrow: '',

            contents: [],
            visitor: [],
            loading: true,
            block: false,
            loadingEpg: true,
        }
    }
   
    componentDidMount(){
        var todayDate = new Date();
		var todayYear = todayDate.getFullYear();
        var todayMonth, todayDay, yesterdayDay, tomorrowDay, todayHour, todayMinute;
        //var todaySecond;
		if ((todayDate.getMonth()+1) < 10) {
			todayMonth = "0"+(todayDate.getMonth()+1);
		} else {
			todayMonth = todayDate.getMonth()+1;
		}
		if ((todayDate.getDate()) < 10) {
			todayDay = "0"+todayDate.getDate();
            yesterdayDay = "0"+(todayDate.getDate()-1);
            tomorrowDay = "0"+(todayDate.getDate()+1);
		} else {
		    todayDay = todayDate.getDate();
            yesterdayDay = todayDate.getDate()-1;
            tomorrowDay = todayDate.getDate()+1;
		}
		if ((todayDate.getHours()) < 10) {
			todayHour = "0"+todayDate.getHours();
		} else {
			todayHour = todayDate.getHours();
		}
		if ((todayDate.getMinutes()) < 10) {
			todayMinute = "0"+todayDate.getMinutes();
		} else {
			todayMinute = todayDate.getMinutes();
		}
		if ((todayDate.getSeconds()) < 10) {
			//todaySecond = "0"+todayDate.getSeconds();
		} else {
			//todaySecond = todayDate.getSeconds();
		}
		var todayDateNew = todayDay+"-"+todayMonth+"-"+todayYear;
		var todayTimeNew = todayHour+":"+todayMinute;
        this.setState({dateToday: todayDateNew});
        this.setState({timeToday: todayTimeNew});

		var yesterdayDateNew = yesterdayDay+"-"+todayMonth+"-"+todayYear;
        this.setState({dateYesterday: yesterdayDateNew});
        var tomorrowDateNew = tomorrowDay+"-"+todayMonth+"-"+todayYear;
        this.setState({dateTomorrow: tomorrowDateNew});

        let { tag } = this.props.match.params;
        fetch(window.backendDomain+'/api/v1/contents/vod/'+tag, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json_contents => {
            console.log(json_contents);
            if (json_contents.status === 'OK') {
                this.setState({contents: json_contents.data});
                this.setState({loading: false});
                // fetch(backendDomain+'/api/v1/vods/epg/view/'+json.data._id, {
                //     method: 'GET',
                //     headers: {
                //         'Content-type': 'application/json',
                //         'auth-token': localStorage.getItem('token')
                //     }
                // })
                // .then(response => response.json())
                // .then(jsonEpg => {
                //     console.log(jsonEpg);
                //     if (jsonEpg.status === 'OK') {
                //         this.setState({epg: jsonEpg.data.epgData});
                //         this.setState({epgLoading: false});
                //     }
                // });
            }
        });
    }
    
    render() {   
        // const vodEpgRender = this.state.epg.map((item) => (
        //     <div className="p-1" key={item._id}>
        //         <center>
        //             <h6 style={{margin: 0, marginBottom: 5}}>
        //                 {
        //                     new Date(this.state.dateToday+" "+this.state.timeToday) >= new Date(this.state.dateToday+" "+item.startTime) 
        //                     && 
        //                     new Date(this.state.dateToday+" "+this.state.timeToday) <= new Date(this.state.dateToday+" "+item.stopTime) ?
        //                     <span style={{backgroundColor: 'white', color: 'black', paddingRight: 5, paddingLeft: 5, marginRight: 5, borderRadius: 10}}>
        //                         <i className="fa fa-circle" style={{color: '#D12B1E', fontSize: 14, marginRight: 5}}></i>LIVE
        //                     </span>
        //                 :
        //                     <span></span>
        //                 }
        //                 {item.title}
        //             </h6>
        //         </center>
        //         <img src={backendCms + `/images/vods/${this.state.vod.logo}`} alt="OyaWatch Logo" width="100%" className="image" />
        //         <div className="col-12">
        //             <center>
        //                 <h6 style={{margin: 0}}>{item.startTime} - {item.stopTime}</h6>
        //                 <h6 style={{margin: 0}}>
        //                     { this.state.dateToday === item.startDate ?
        //                         <div>Today</div>
        //                     :
        //                         <div>
        //                         {
        //                             this.state.dateYesterday === item.startDate ?
        //                             <div>Yesterday</div>
        //                             :
        //                             <div>
        //                             {
        //                                 this.state.dateTomorrow === item.startDate ?
        //                                 <div>Tomorrow</div>
        //                                 :
        //                                 item.startDate
        //                             }
        //                             </div>
        //                         }
        //                         </div>
        //                     }
        //                 </h6>
        //             </center>
        //         </div>
        //     </div>
        // )) 
        const checkSubscription = (subscriptions) => {
            var sub_block = false;
            var todayDate = new Date();
            var todayYear = todayDate.getFullYear();
            var todayMonth = "";
            var todayDay = "";
            if ((todayDate.getMonth()+1) < 10) {
                todayMonth = "0"+(todayDate.getMonth()+1);
            } else {
                todayMonth = todayDate.getMonth()+1;
            }
            if ((todayDate.getDate()) < 10) {
                todayDay = "0"+todayDate.getDate();
            } else {
                todayDay = todayDate.getDate();
            }
            var dateNow = todayYear+"-"+todayMonth+"-"+todayDay+"T23:59:59.999Z";
            //console.log(dateNow);
            if (subscriptions.length < 1) {
                sub_block = true;
            } else {
                for(var i = 0; i < subscriptions.length; i++){
                    var sub_end_date = subscriptions[i]['end_date'];
                    if (dateNow > sub_end_date) {
                        sub_block = true;
                    } else {
                        sub_block = false;
                    }
                }
            }
            if (sub_block === true) {
                return  <button className="btn btn-primary w-100 mt-4 disabled">NO SUBSCRIPTION</button>
            } else {
                return <Link to={`/vod/play/${this.state.contents.vodsList[0].tag}`}>
                <button className="btn btn-primary w-100 mt-4">WATCH NOW</button>
            </Link>
            }
        }
      return (
          
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    
                    { this.state.loading ?
                        <div style={{marginTop: 50}}>
                            <Loader />
                        </div>
                        :
                        <>
                        {
                            this.state.contents.content[0].status === 1 ?
                            <div className="row">
                                <div className="col-md-6 p-1">
                                    <center>
                                        <img src={window.backendUrl+`/${this.state.contents.vodsList[0].image}`} alt={this.state.contents.vodsList[0].name} className="image" width="50%" />
                                    </center>
                                </div>
                                <div className="col-md-6 p-1">
                                    <div className="col-12 vod-description">
                                        <center><h2>{this.state.contents.vodsList[0].name}</h2></center>
                                        {
                                            checkSubscription(this.state.contents.userSubscriptions)
                                        }
                                        <p className="mt-4">
                                            {this.state.contents.vodsList[0].description}
                                        </p>
                                        <h5 className="mt-4">
                                            {this.state.contents.vodsCategory[0].name}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        :
                        <></>
                        }
                        </>
                    }
                    
                    <br />
                    <hr />
                    <div className="row">
                        <div className="col-12 p-1">
                            <center><h4>Related Content</h4></center>
                        </div>
                        {/* <div className="col-12 p-0">
                            <Carousel 
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                infinite={false}
                                keyBoardControl={true}
                                >
                                {vodEpgRender}
                            </Carousel>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default VodPreview
