import React from 'react';
import Skeleton from "@mui/material/Skeleton";

export default function AllChannelsSkeleton(){
    return(
        <>
            <div className="row mt-4 mb-4">
                <div className="col-6 p-0">
                    <Skeleton
                        variant="text"
                        width={'50%'}
                        height={70}
                        style={{backgroundColor: 'gray'}}
                    />
                </div> 
                <div className="col-6 p-0">
                    <Skeleton
                        variant="text"
                        width={'100%'}
                        height={70}
                        style={{backgroundColor: 'gray'}}
                    />
                </div> 
            </div>
            <div className="row">
                <div className="col-12 p-1">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                         <div className="col-md-3 mb-4">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={180}
                                style={{backgroundColor: 'gray'}}
                                className="image"
                            />
                            <Skeleton
                                variant="text"
                                width={'100%'}
                                height={30}
                                style={{backgroundColor: 'gray'}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}