import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

class ManageSubscription extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contents: [],
            loading: true,
        }
    }
        
    componentDidMount(){
        if(this.state.loading === true){
            fetch(window.backendDomain+'/api/v1/contents/availability', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json.data);
                if (json.status === 'OK') {
                    this.setState({contents: json.data});
                    this.setState({loading: false});
                }
            });
        }
    }
    
    render() {   
      return (
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Manage Subscription</h3>
                            <br />
                        </div>
                    </div>
                    { this.state.loading === true ?
                        <div className="row">
                            <div className="col-12">
                                <center><Loader /></center>
                            </div>
                        </div>
                        :
                        <>
                        {
                            this.state.contents[2].status === 1 ? //to be changed to 1
                            <>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <h4>Plans/Subscriptions</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <Link to="subscription/purchase"><button className="btn btn-primary w-100 mt-3">Click to Subscribe</button></Link>        
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="subscription/view"><button className="btn btn-primary w-100 mt-3">My Subscriptions</button></Link>  
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="subscription/change"><button className="btn btn-primary w-100 mt-3">Change Subscription</button></Link>  
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="subscription/promo"><button className="btn btn-primary w-100 mt-3">Use Promo Code</button></Link>  
                                    </div>
                                </div>
                                <br />
                            </>
                        :
                        <></>
                        }
                        </>
                    }

                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <h4>Devices</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <Link to="subscription/devices"><button className="btn btn-primary w-100 mt-3">My Devices</button></Link>  
                        </div>
                        { this.state.loading === true ?
                            <div className="row">
                                <div className="col-12">

                                </div>
                            </div>
                            :
                            <>
                            {
                                this.state.contents[2].status === 1 ? //to be changed to 1
                                <>
                                    <div className="col-md-3">
                                        <Link to="subscription/add-device"><button className="btn btn-primary w-100 mt-3">Add Additional Device</button></Link>        
                                    </div>
                                </>
                                :
                                <></>
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default ManageSubscription
