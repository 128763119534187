import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Swal from "sweetalert2"; 
import i18n from 'i18n-js';

import Header from "../components/HeaderOut";
import Footer from "../components/Footer";

import loadingImage from '../images/loader.gif';

class ForgotPassword extends Component {
    constructor(props) {
      super(props)

      this.state = {
        email: '',
        buttonLoading: false,
      }
    }
        
    componentDidMount(){
        
    }
    
    render() { 
      const handleSubmit = () => {
        this.setState({buttonLoading: true});
        const email = (this.state.email).toLowerCase();
        if(email === ""){
          Swal.fire({  
            title: `${i18n.t('7')}`,  
            icon: 'error',   
            text: `${i18n.t('11')}`,  
          });
          this.setState({buttonLoading: false});
        } else {
          const data  = {
            email: email
          }
          
          fetch(window.backendDomain+'/api/v1/auth/reset', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
              Swal.fire({  
                title: json.title,  
                type: 'success',
                icon: 'success',   
                text: json.message,  
              });
            } else {
              Swal.fire({  
                title: json.title,  
                type: 'error',
                icon: 'error',   
                text: json.message,  
              });
            }
            this.setState({buttonLoading: false});
          });
        }
      }
      
      let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('95')}</Button>;
      switch (this.state.buttonLoading) {
        case false:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('95')}</Button>;
          break;
        case true:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /></Button>
          break;
        default:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('95')}</Button>;
      }  
      return (
        <div>
          <Header />
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="form-div">
                    <center><h4>{i18n.t('94')}</h4></center>
                    <hr />
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{i18n.t('1')}</Form.Label>
                      <Form.Control size="lg" type="email" placeholder={i18n.t('11')} onChange={(e) => this.setState({email: e.target.value})} />
                    </Form.Group>
                    {submitButton}
                    <Form.Group className="mt-3">
                        <div className="row">
                            <div className="col-12">
                              <center>
                                <Link to="/login" className="link"><u>{i18n.t('18')}</u></Link>
                              </center>
                            </div>
                        </div>
                    </Form.Group>
                  </div>     
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }        
}

export default ForgotPassword
