import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
import i18n from 'i18n-js';

import Header from "../components/HeaderOut";
import Footer from "../components/Footer";

import loadingImage from '../images/loader.gif';

class UnsubscribeEmail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            buttonLoading: false,
        }
    }

    componentDidMount() {

    }

    render() {
        let { email, id } = this.props.match.params;
        const handleSubmit = () => {
            this.setState({ buttonLoading: true });
            if (email === "") {
                Swal.fire({
                    title: `${i18n.t('7')}`,
                    icon: 'error',
                    text: `${i18n.t('11')}`,
                });
                this.setState({ buttonLoading: false });
            } else {
                const data = {
                    userId: id,
                    email: email
                }

                fetch(window.backendDomain + '/api/v1/broadcast/unsubscribe/email', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-type': 'application/json'
                    }
                })
                    .then(response => response.json())
                    .then(json => {
                        //console.log(json);
                        if (json.status === 'OK') {
                            Swal.fire({
                                title: json.title,
                                type: 'success',
                                icon: 'success',
                                text: json.message,
                            });
                        } else {
                            Swal.fire({
                                title: json.title,
                                type: 'error',
                                icon: 'error',
                                text: json.message,
                            });
                        }
                        this.setState({ buttonLoading: false });
                    });
            }
        }

        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('95')}</Button>;
        switch (this.state.buttonLoading) {
            case false:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('95')}</Button>;
                break;
            case true:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{ width: '20px' }} /></Button>
                break;
            default:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('95')}</Button>;
        }
        return (
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-div">
                                    <center><h5>You are about to unsubscribe from OyaWatch mailing list. You will no longer receive any newsletter and broadcast from OyaWatch. Click on the button below to proceed!</h5></center>
                                    <hr />

                                    {submitButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default UnsubscribeEmail;
