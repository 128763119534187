import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import i18n from 'i18n-js';

import Logo from "../images/logo.png";

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
        contentsCategories: [],
        loadingCategories: true
    }
  }

  componentDidMount(){
    //fetch('https://ipapi.co/json/', {
      fetch('https://ipinfo.io/?token=d16f793fc3ce26', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(json => {
        //console.log(json);
        //console.log(Object.keys(json).length>3);
        if(Object.keys(json).length>3){
            //localStorage.setItem('visitor', json.country_code);
            localStorage.setItem('visitor', json.country);
        }
    });

    this.setState({loadingCategories: true});
      fetch(window.backendDomain+'/api/v1/free/contents/categories/view', {
          method: 'GET',
          headers: {
            'Content-type': 'application/json'
          }
      })
      .then(response => response.json())
      .then(json => {
          //console.log(json);
          if (json.status === 'OK') {
              this.setState({contentsCategories: json.data});
              this.setState({loadingCategories: false});
          }
      });
  }

    render() {   
      return (
        <Navbar collapseOnSelect expand="lg" className="header" bg="" fixed="top" variant="dark">
            <Container>
            <Navbar.Brand><Link to="/home"><img src={Logo} alt="OyaWatch Logo" height="50px" /></Link></Navbar.Brand>
            <Navbar.Toggle className="fa fa-bars white" aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
                    <Nav.Link><Link to="/home" className="link">HOME</Link></Nav.Link>
                    {
                        this.state.loadingCategories === false ?
                        <>
                            {
                                this.state.contentsCategories.content[0].status === 1 ?
                                <>
                                    <NavDropdown title="LIVE CHANNELS" id="collasible-nav-dropdown">
                                    <NavDropdown.Item><Link to="/home/channels/all" className="link">ALL CHANNELS</Link></NavDropdown.Item>
                                        {
                                            this.state.contentsCategories.channelsCategory.map((item) => (
                                                <NavDropdown.Item  key={item._id}><Link to={`/home/channels/${item._id}`} className="link">{(item.name).toUpperCase()}</Link></NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                </>
                            :
                                <></>
                            }
                            {
                                this.state.contentsCategories.content[1].status === 1 ?
                                <>
                                    <NavDropdown title="VOD" id="collasible-nav-dropdown">
                                        <NavDropdown.Item><Link to="/home/vods/all" className="link">ALL VOD</Link></NavDropdown.Item>
                                        {
                                            this.state.contentsCategories.vodsCategory.map((item) => (
                                                <NavDropdown.Item  key={item._id}><Link to={`/vods/${item._id}`} className="link">{(item.name).toUpperCase()}</Link></NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                </>
                            :
                                <></>
                            }
                        </>
                        :
                        <></>
                    }
                </Nav>
            <Nav>
              <Nav.Link><Link to="/register" className="link">{i18n.t('17')}</Link></Nav.Link>
              <Nav.Link><Link to="/login" className="link">{i18n.t('3')}</Link></Nav.Link>
            </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
      );
    }        
}

export default Header
