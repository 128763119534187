import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 

import Header from "../components/Header";
import Footer from "../components/Footer";
import loadingImage from '../images/loader.gif';

class UsePromoCode extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            code: '',
            buttonLoading: false,
            loading: false,
        }
    }

    componentDidMount(){
        
    }
    
    render() {
        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        switch (this.state.buttonLoading) {
            case false:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
            break;
            case true:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
            break;
            default:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        } 

        const handleSubmit = () => {
            this.setState({buttonLoading: true});
            const code = this.state.code;
            if(code === ""){
              Swal.fire({  
                title: 'ERROR',
                icon: 'error',   
                text: 'Please, enter promo code!',  
              });
              this.setState({buttonLoading: false});
            } else {
                const data  = {
                    promoCode: code
                }
                fetch(window.backendDomain+'/api/v1/promocode/subscribe', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub => {
                    //console.log(json_sub);
                    this.setState({buttonLoading: false});
                    if (json_sub.status === 'OK') {
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'success',   
                            text: json_sub.message,  
                        });
                    } else {
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'error',   
                            text: json_sub.message,  
                        });
                    }
                });
            }
        }

        return (
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-div-2">
                                            <center><h4>Use Promo Code</h4></center>
                                            <hr />
                                            <Form.Group className="mb-3">
                                                <Form.Label>Code</Form.Label>
                                                <input type="text" placeholder='Enter Promo Code' className="form-control" onChange={(e) => this.setState({code: e.target.value})}/>
                                            </Form.Group>
                                            
                                            {submitButton}
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default UsePromoCode;
