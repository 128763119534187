import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Skeleton from "@mui/material/Skeleton";

import Header from "../components/Header";
import Footer from "../components/Footer";
//import Loader from "../components/Loader";
import ChannelsSkeleton from '../components/ChannelsSkeleton';

import { Link } from 'react-router-dom';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

class Channels extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contents: [],
            loading: true,
        }
    }
        
    componentDidMount(){
        //console.log(window.backendDomain);
        if(this.state.loading === true){
            fetch(window.backendDomain+'/api/v1/contents/channels/view', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (json.status === 'OK') {
                    this.setState({contents: json.data});
                    this.setState({loading: false});
                }
            });
        }
    }
    
    render() {  
        if (this.state.loading === false) {
            console.log(this.state.contents.content[0].status);
        }
        return (
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        {
                            this.state.loading === true ?
                            // <div className="mt-5">
                            //     <Loader />    
                            // </div>
                            <>
                                <div className="row">
                                    <div className="col-6 p-0">
                                        <Skeleton
                                            variant="text"
                                            width={200}
                                            height={50}
                                            style={{backgroundColor: 'gray'}}
                                        />
                                    </div> 
                                    <div className="col-6 p-0">
                                        <Skeleton
                                            variant="text"
                                            width={200}
                                            height={50}
                                            style={{backgroundColor: 'gray'}}
                                            className="pull-right"
                                        />
                                    </div> 
                                </div>
                                <ChannelsSkeleton />
                                <ChannelsSkeleton />
                            </>
                            :
                            <>
                                {
                                    this.state.contents.content[0].status === 1 ?
                                    <>
                                        <div className="row">
                                            <div className="col-6 p-0">
                                                <h3>Live Channels</h3>
                                            </div>
                                            <div className="col-6 p-0">
                                                <Link to="/channels/all" className="link pull-right"><h6>All Channels</h6></Link>
                                            </div>
                                        </div>
                                        { 
                                            this.state.contents.channelsCategory.map((item) => (
                                                <div>
                                                    <div className="row" key={item._id}>
                                                        <div className="col-12 p-1">
                                                            <div className="row">
                                                                <div className="col-6 ">
                                                                    <h4>{item.name}</h4>
                                                                </div>
                                                                <div className="col-6">
                                                                    <Link to={`/channels/${item._id}`} className="link pull-right"><h6>More</h6></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 p-0">
                                                            <Carousel
                                                                swipeable={true}
                                                                draggable={true}
                                                                showDots={false}
                                                                responsive={responsive}
                                                                infinite={false}
                                                                keyBoardControl={true}
                                                                >
                                                                {
                                                                    this.state.contents.channelsList.filter(channels => channels.category === item._id).map((channelsItem) => (
                                                                        <div className="p-1" key={channelsItem._id}>
                                                                            <Link to={`/channel/${channelsItem.tag}`} className="link">
                                                                                <img src={window.backendUrl+`/${channelsItem.logo}`} alt={channelsItem.name} className="image" width="100%" />
                                                                                <div className="col-12 pt-2">
                                                                                    <center><h6>{channelsItem.name}</h6></center>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </Carousel>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                    :
                                    <></>
                                }
                            </>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default Channels
