import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Logo from "../images/logo.png";

class Header extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            contentsUser: [],
            loadingUser: true,
            contentsCategories: [],
            loadingCategories: true
        }
    } 
          
    componentDidMount(){
        //localStorage.setItem('visitor', 'NG');

        // //fetch('https://ipapi.co/json/', {
        // fetch('https://ipinfo.io/?token=d16f793fc3ce26', {
        //     method: 'GET',
        //     headers: {
        //         'Content-type': 'application/json'
        //     }
        // })
        // .then(response => response.json())
        // .then(json => {
        //     console.log(json);
        //     //console.log(Object.keys(json).length>3);
        //     if(Object.keys(json).length>3){
        //         //localStorage.setItem('visitor', json.country_code);
        //         localStorage.setItem('visitor', json.country);
        //     }
        // });
        const data = {
            deviceId: 'fcb9ef9019deac5377532c3824c3061c'
        }
        fetch(window.backendDomain+'/api/v1/profile/check-device', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
                if (json.data === null) {
                    
                } else {
                    if (json.data.status === 0) {
                        localStorage.removeItem("token");
                        window.location="/#/login";
                    }
                }
            }
        });

        fetch(window.backendDomain+'/api/v1/profile/view', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
                this.setState({contentsUser: json.data});
                localStorage.setItem('userId', json.data.user._id);
                window.contentsUser = json.data;
                this.setState({loadingUser: false});
                fetch(window.backendDomain+'/api/v1/contents/categories/view', {
                    method: 'GET',
                    headers: {
                      'Content-type': 'application/json',
                      'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    if (json.status === 'OK') {
                        this.setState({contentsCategories: json.data});
                        this.setState({loadingCategories: false});
                    }
                });
            } else {
                localStorage.removeItem("token");
                window.location="/#/login";
            }
        });
    }
    
    render() {   
        const logout = () => {
            localStorage.removeItem("token");
            window.location="/#/login";
        }
        
        return (
            <Navbar collapseOnSelect expand="lg" className="header" bg="" fixed="top" variant="dark">
                <Container style={{padding: 0}}>
                <Navbar.Brand><Link to="/"><img src={Logo} alt="OyaWatch Logo" height="50px" /></Link></Navbar.Brand>
                <Navbar.Toggle className="fa fa-bars white" aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link><Link to="/" className="link">HOME</Link></Nav.Link>
                    {
                        this.state.loadingCategories === false ?
                        <>
                            {
                                this.state.contentsCategories.content[0].status === 1 ?
                                <>
                                    <NavDropdown title="LIVE CHANNELS" id="collasible-nav-dropdown">
                                    <NavDropdown.Item><Link to="/channels/all" className="link">ALL CHANNELS</Link></NavDropdown.Item>
                                        {
                                            this.state.contentsCategories.channelsCategory.map((item) => (
                                                <NavDropdown.Item  key={item._id}><Link to={`/channels/${item._id}`} className="link">{(item.name).toUpperCase()}</Link></NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                </>
                            :
                                <></>
                            }
                            {
                                this.state.contentsCategories.content[1].status === 1 ?
                                <>
                                    <NavDropdown title="VOD" id="collasible-nav-dropdown">
                                        <NavDropdown.Item><Link to="/vods/all" className="link">ALL VOD</Link></NavDropdown.Item>
                                        {
                                            this.state.contentsCategories.vodsCategory.map((item) => (
                                                <NavDropdown.Item  key={item._id}><Link to={`/vods/${item._id}`} className="link">{(item.name).toUpperCase()}</Link></NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                </>
                            :
                                <></>
                            }
                        </>
                        :
                        <></>
                    }
                </Nav>

                <Nav>
                {
                    this.state.loadingUser === false ?
                    <NavDropdown title={(this.state.contentsUser.user.name).toUpperCase()} id="collasible-nav-dropdown">
                        <NavDropdown.Item><Link to="/my-account" className="link"><i className="fa fa-user"></i> MY ACCOUNT</Link></NavDropdown.Item>
                        <NavDropdown.Item><Link to="/manage-subscription" className="link"><i className="fa fa-credit-card"></i> MANAGE SUBSCRIPTION</Link></NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => logout()}><i className="fa fa-sign-out"></i> LOGOUT</NavDropdown.Item>
                    </NavDropdown>
                    :
                    <></>
                }
                </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }        
}

export default Header
