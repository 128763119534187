import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 
import { usePaystackPayment } from 'react-paystack';

import Header from "../components/Header";
import Footer from "../components/Footer";
import loadingImage from '../images/loader.gif';
import cardImage from '../images/pay.png';

class PurchasePlan extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            planId: 1,
            deviceNo: '',
            buttonLoading: false,
            showModal: false,
            packageName: '',
            packagePrice: '',
            packagePriceDollar: '',
            packagePriceActual: 0,
            packagePriceDollarActual: 0,
            packageId: '',
            paystackPlanCodeNaira: '',
            paystackPlanCodeDollar: '',
            firstUser: false,
            currentSubscription: '',
            currentSubscriptionData: [],
            loading: false,
            displayCostNaira: '',
            displayCostDollar: '',
        }
    }

    // {
    //     "reference": "1633440215936166410749",
    //     "trans": "1368712410",
    //     "status": "success",
    //     "message": "Approved",
    //     "transaction": "1368712410",
    //     "trxref": "1633440215936166410749"
    // }
    
    // {
    //     "status": false,
    //     "message": "This subscription is already in place."
    // }
    
    componentDidMount(){
        fetch(window.backendDomain+'/api/v1/profile/subscription', {
            method: 'GET',
            headers: {
            'Content-type': 'application/json',
            'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json.data);
            if (json.status === 'OK') {
                //var currentSubscriptionId = json.data.package_id;
                if (json.data === null){
                    this.setState({firstUser: true});
                }
                fetch(window.backendDomain+'/api/v1/profile/subscriptions', {
                    method: 'GET',
                    headers: {
                      'Content-type': 'application/json',
                      'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub => {
                    //console.log(json_sub.data[json_sub.data.length - 1][0].endDate);
                    if (json_sub.status === 'OK' && json_sub.data.length > 0) {
                        this.setState({currentSubscription: json_sub.data[0][1].name});
                        this.setState({currentSubscriptionData: json_sub.data[json_sub.data.length - 1]});
                    }
                    this.setState({loading: false});
                });
            }
        });
        
    }
    
    render() {
        // if(this.state.loading === false && this.state.firstUser === false){
        //     console.log(this.state.currentSubscription)
        // } 
        const startDate = new Date()
        var date = new Date();
        const endDate = new Date(date.setMonth(date.getMonth()+parseInt(this.state.planId)));
        //console.log(startDate.toISOString().split('T')[0]);
        //console.log(endDate.toISOString().split('T')[0]);
        
        const handleClose = () => this.setState({showModal: false});
        
        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        switch (this.state.buttonLoading) {
            case false:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
            break;
            case true:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
            break;
            default:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        } 

        const handleDeviceChange = (value) => {
            this.setState({deviceNo: value});
            const planId = this.state.planId;
            const deviceNo = value;
            if(planId === "" || deviceNo === ""){
                
            } else {
                var tag = planId+"_"+deviceNo;
                fetch(window.backendDomain+'/api/v1/packages/view/'+tag, {
                    method: 'GET',
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    if (json.status === 'OK' && json.data.length > 0) {
                        this.setState({displayCostNaira: json.data[0].price});
                        this.setState({displayCostDollar: json.data[0].price_in_dollar});
                    } else {
                        this.setState({displayCostNaira: ''});
                        this.setState({displayCostDollar: ''});
                    }
                });
            }
        }

        const handleSubmit = () => {
            this.setState({buttonLoading: true});
            const planId = this.state.planId;
            const deviceNo = this.state.deviceNo;
            if(planId === "" || deviceNo === ""){
              Swal.fire({  
                title: 'ERROR',
                icon: 'error',   
                text: 'Please, select plan and device!',  
              });
              this.setState({buttonLoading: false});
            } else {
                var tag = planId+"_"+deviceNo;
                fetch(window.backendDomain+'/api/v1/packages/view/'+tag, {
                    method: 'GET',
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.setState({buttonLoading: false});
                    //console.log(json);
                    if (json.status === 'OK') {
                        this.setState({packageName: json.data[0].name});
                        if (this.state.firstUser === true) {
                            this.setState({packagePrice: 55});
                            this.setState({packagePriceDollar: 1.02});
                        } else {
                            this.setState({packagePrice: json.data[0].price});
                            this.setState({packagePriceDollar: json.data[0].price_in_dollar});
                        }
                        this.setState({packagePriceActual: json.data[0].price});
                        this.setState({packagePriceDollarActual: json.data[0].price_in_dollar});

                        this.setState({packageId: json.data[0]._id});
                        this.setState({paystackPlanCodeNaira: json.data[0].paystack_plan_code_naira});
                        this.setState({paystackPlanCodeDollar: json.data[0].paystack_plan_code_dollar});
                        this.setState({showModal: true});
                    } else {
                        Swal.fire({  
                            title: json.title,  
                            icon: 'error',   
                            text: json.message,  
                        });
                    }
                    this.setState({buttonLoading: false});
                });
            }
        } 
        
        const configNaira = {
            reference: (new Date()).getTime().toString()+Math.floor((Math.random() * 1000000000) + 1),
            email: window.contentsUser.user.email,
            amount: this.state.packagePrice * 100,
            currency: 'NGN',
            metadata: {
                name: window.contentsUser.user.name,
                phone: window.contentsUser.user.phone,
            },
            publicKey: window.paystackPublicKey,
        };
        const onSuccessNaira = (reference) => {
            const dataSub  = {
                packageId: this.state.packageId,
                startDate: startDate,
                endDate: endDate
            }
            fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                method: 'POST',
                body: JSON.stringify(dataSub),
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                //console.log(json_sub);
                if (json_sub.status === 'OK') {
                    this.setState({showModal: false});
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'success',   
                        text: json_sub.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'error',   
                        text: json_sub.message,
                    });
                }
            });
            if (this.state.firstUser === true) {
                const dataRefund  = {
                    //transaction: '16334342394675515400',
                    transaction: reference.trxref,
                    amount: 50*100,
                    currency: "NGN", 
                    customer_note: "Your card details has been successfully verified, subsquently after your free trail expiry date, your card will be  billed automatically",
                    merchant_note: "Customer Card verification successful"
                }
                fetch('https://api.paystack.co/refund', {
                    method: 'POST',
                    body: JSON.stringify(dataRefund),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_refund => {
                    //console.log(json_refund);
                });
            }
            /*
            const data  = {
                customer: window.contentsUser.user.email,
                plan: this.state.paystackPlanCodeNaira,
                start_date: endDate
            }
            fetch('https://api.paystack.co/subscription', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+window.paystackSecretKey
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (this.state.firstUser === true) {
                    const dataRefund  = {
                        //transaction: '16334342394675515400',
                        transaction: reference.trxref,
                        amount: 50*100,
                        currency: "NGN", 
                        customer_note: "Your card details has been successfully verified, subsquently after your free trail expiry date, your card will be  billed automatically",
                        merchant_note: "Customer Card verification successful"
                    }
                    fetch('https://api.paystack.co/refund', {
                        method: 'POST',
                        body: JSON.stringify(dataRefund),
                        headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer '+window.paystackSecretKey
                        }
                    })
                    .then(response => response.json())
                    .then(json_refund => {
                        //console.log(json_refund);
                    });
                }

                // const dataSub  = {
                //     packageId: this.state.packageId,
                //     startDate: startDate,
                //     endDate: endDate
                // }
                // fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                //     method: 'POST',
                //     body: JSON.stringify(dataSub),
                //     headers: {
                //     'Content-type': 'application/json',
                //     'auth-token': localStorage.getItem('token')
                //     }
                // })
                // .then(response => response.json())
                // .then(json_sub => {
                //     //console.log(json_sub);
                //     if (json_sub.status === 'OK') {
                //         this.setState({showModal: false});
                //         Swal.fire({  
                //             title: json_sub.title,  
                //             icon: 'success',   
                //             text: json_sub.message,  
                //         });
                //     } else {
                //         Swal.fire({  
                //             title: json_sub.title,  
                //             icon: 'error',   
                //             text: json_sub.message,
                //         });
                //     }
                // });
            });
            */
        };
        const onCloseNaira = () => {
            //console.log('closed')
        }
        const PaystackHookNaira = () => {
            const initializePayment = usePaystackPayment(configNaira);
            return (
                <div>
                    <Button size="lg" variant="primary" type="submit" onClick={() => {
                        initializePayment(onSuccessNaira, onCloseNaira)
                    }} className="w-100">Naira Card</Button>
                </div>
            );
        };

        const configDollar = {
            reference: (new Date()).getTime().toString()+Math.floor((Math.random() * 1000000000) + 1),
            email: window.contentsUser.user.email,
            amount: this.state.packagePriceDollar * 100,
            currency: 'USD',
            metadata: {
                name: window.contentsUser.user.name,
                phone: window.contentsUser.user.phone,
            },
            publicKey: window.paystackPublicKey,
        };
        const onSuccessDollar = (reference) => {
            //console.log(reference);
            const dataSub  = {
                packageId: this.state.packageId,
                startDate: startDate,
                endDate: endDate
            }
            fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                method: 'POST',
                body: JSON.stringify(dataSub),
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                //console.log(json_sub);
                if (json_sub.status === 'OK') {
                    this.setState({showModal: false});
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'success',   
                        text: json_sub.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'error',   
                        text: json_sub.message,  
                    });
                }
            });
            if (this.state.firstUser === true) {
                const dataRefund  = {
                    transaction: reference.trxref,
                    amount: 1*100,
                    currency: "USD", 
                    customer_note: "Your card details has been successfully verified, subsquently after your free trail expiry date, your card will be  billed automatically",
                    merchant_note: "Customer Card verification successful"
                }
                fetch('https://api.paystack.co/refund', {
                    method: 'POST',
                    body: JSON.stringify(dataRefund),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_refund => {
                    //console.log(json_refund);
                });
            }
            /*
            const data  = {
                customer: window.contentsUser.user.email,
                plan: this.state.paystackPlanCodeDollar,
                start_date: endDate
            }
            fetch('https://api.paystack.co/subscription', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+window.paystackSecretKey
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (this.state.firstUser === true) {
                    const dataRefund  = {
                        transaction: reference.trxref,
                        amount: 1*100,
                        currency: "USD", 
                        customer_note: "Your card details has been successfully verified, subsquently after your free trail expiry date, your card will be  billed automatically",
                        merchant_note: "Customer Card verification successful"
                    }
                    fetch('https://api.paystack.co/refund', {
                        method: 'POST',
                        body: JSON.stringify(dataRefund),
                        headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer '+window.paystackSecretKey
                        }
                    })
                    .then(response => response.json())
                    .then(json_refund => {
                        //console.log(json_refund);
                    });
                }
                // const dataSub  = {
                //     packageId: this.state.packageId,
                //     startDate: startDate,
                //     endDate: endDate
                // }
                // fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                //     method: 'POST',
                //     body: JSON.stringify(dataSub),
                //     headers: {
                //     'Content-type': 'application/json',
                //     'auth-token': localStorage.getItem('token')
                //     }
                // })
                // .then(response => response.json())
                // .then(json_sub => {
                //     //console.log(json_sub);
                //     if (json_sub.status === 'OK') {
                //         this.setState({showModal: false});
                //         Swal.fire({  
                //             title: json_sub.title,  
                //             icon: 'success',   
                //             text: json_sub.message,  
                //         });
                //     } else {
                //         Swal.fire({  
                //             title: json_sub.title,  
                //             icon: 'error',   
                //             text: json_sub.message,  
                //         });
                //     }
                // });
            });
            */
        };
        const onCloseDollar = () => {
            //console.log('closed dollar')
        }
        const PaystackHookDollar = () => {
            const initializePayment = usePaystackPayment(configDollar);
            return (
                <div>
                    <Button size="lg" variant="primary" type="submit" onClick={() => {
                        initializePayment(onSuccessDollar, onCloseDollar)
                    }} className="w-100">Dollar Card</Button>
                </div>
            );
        };

        var todayDate = new Date();
        var todayYear = todayDate.getFullYear();
        var todayMonth = "";
        var todayDay = "";
        if ((todayDate.getMonth()+1) < 10) {
            todayMonth = "0"+(todayDate.getMonth()+1);
        } else {
            todayMonth = todayDate.getMonth()+1;
        }
        if ((todayDate.getDate()) < 10) {
            todayDay = "0"+todayDate.getDate();
        } else {
            todayDay = todayDate.getDate();
        }
        var dateNow = todayYear+"-"+todayMonth+"-"+todayDay+"T23:59:59.999Z";

        return (
            <div>
                <Modal show={this.state.showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Purchase Subscription - Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.currentSubscriptionData.length > 1 ?
                                <>
                                {
                                    this.state.loading === false && this.state.firstUser === false && this.state.currentSubscription === this.state.packageName && this.state.currentSubscriptionData[0].endDate > dateNow ?
                                        <center>
                                            <h5>You already have {this.state.packageName} package!</h5>
                                        </center>
                                    :
                                    <center>
                                        <p><b>Package:</b> {this.state.packageName}</p>
                                        {
                                            this.state.firstUser === true && <p><b>First Payment (PROMO):</b> &#8358;{this.state.packagePrice} (${this.state.packagePriceDollar})</p>
                                        }
                                        <p><b>{this.state.firstUser === true && 'Next '}Subscription Payment + VAT Charges:</b> &#8358;{this.state.packagePriceActual} (${this.state.packagePriceDollarActual})</p>
                                        <hr />
                                        <h5>Please, choose a payment currency</h5>
                                        <p>Credit or Debit Card <img src={cardImage} alt="Payment Options" width="150" /></p>
                                        <div className="row">
                                            <div className="col-6">
                                                <PaystackHookNaira />
                                            </div>
                                            <div className="col-6">
                                                <PaystackHookDollar />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-12">
                                                <p>By clicking on the payment button, you agreed with <a href="https://oyawatch.com/terms-of-use/" target="_blank" rel="noreferrer noopener" >Terms and Conditions</a>, <a href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="https://oyawatch.com/cookie-policy/" target="_blank" rel="noreferrer noopener">Cookie Policy</a></p>
                                                <center><p>Secure Server <i className="fa fa-lock" style={{color: 'green'}}></i></p></center>
                                            </div>
                                        </div>
                                    </center>
                                }
                                </>
                            :
                            <center>
                                <p><b>Package:</b> {this.state.packageName}</p>
                                {
                                    this.state.firstUser === true && <p><b>First Payment (PROMO):</b> &#8358;{this.state.packagePrice} (${this.state.packagePriceDollar})</p>
                                }
                                <p><b>{this.state.firstUser === true && 'Next '}Subscription Payment + VAT Charges:</b> &#8358;{this.state.packagePriceActual} (${this.state.packagePriceDollarActual})</p>
                                <hr />
                                <h5>Please, choose a payment currency</h5>
                                <p>Credit or Debit Card <img src={cardImage} alt="Payment Options" width="150" /></p>
                                <div className="row">
                                    <div className="col-6">
                                        <PaystackHookNaira />
                                    </div>
                                    <div className="col-6">
                                        <PaystackHookDollar />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <p>By clicking on the payment button, you agreed with <a href="https://oyawatch.com/terms-of-use/" target="_blank" rel="noreferrer noopener" >Terms and Conditions</a>, <a href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="https://oyawatch.com/cookie-policy/" target="_blank" rel="noreferrer noopener">Cookie Policy</a></p>
                                        <center><p>Secure Server <i className="fa fa-lock" style={{color: 'green'}}></i></p></center>
                                    </div>
                                </div>
                            </center>
                        }
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-div-2">
                                            <center><h4>Purchase Subscription</h4></center>
                                            <hr />
                                            <Form.Group className="mb-3">
                                                <Form.Label>Plan</Form.Label>
                                                <select className="form-control" onChange={(e) => this.setState({planId: e.target.value})}>
                                                    <option value="">Select plan</option>
                                                    <option value="1">1 month</option>
                                                    <option value="3">3 months</option>
                                                    <option value="6">6 months</option>
                                                    <option value="12">1 year</option>
                                                </select>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Devices</Form.Label>
                                                {
                                                    this.state.planId === '12' ?
                                                    <select className="form-control" onChange={(e) => handleDeviceChange(e.target.value)}>
                                                        <option value="">Select device</option>
                                                        <option value="5">5 devices</option>
                                                    </select>
                                                    :
                                                    <select className="form-control" onChange={(e) => handleDeviceChange(e.target.value)}>
                                                        <option value="">Select device</option>
                                                        <option value="1">1 device</option>
                                                        <option value="2">2 devices</option>
                                                        <option value="3">3 devices</option>
                                                        <option value="4">4 devices</option>
                                                        <option value="5">5 devices</option>
                                                    </select>
                                                }
                                            </Form.Group>
                                            <center>
                                                { this.state.displayCostNaira !== '' && this.state.displayCostDollar !== '' ?
                                                    <Form.Label>Subscription Cost + VAT charges: &#8358;{this.state.displayCostNaira} (${this.state.displayCostDollar})</Form.Label>
                                                :
                                                    <></>
                                                }
                                            </center>
                                            {submitButton}
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default PurchasePlan
