import React, { Component } from 'react';
import LoadingImage from "../images/loader.gif";

class Loader extends Component {
    render() {   
      return (
        <div className="row">
            <div className="col-12">
                <center>
                    <h5><img src={LoadingImage} alt="Loading..." width="25px" /> Loading...</h5>
                </center>
            </div>
        </div>
      );
    }        
}

export default Loader
