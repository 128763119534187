import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import Form from 'react-bootstrap/Form';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { Link } from 'react-router-dom';

class VodCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contents: [],
            loading: true,
            search: '',
        }
    }
        
    componentDidMount(){
        let { category } = this.props.match.params;
        if(this.state.loading === true){
            fetch(window.backendDomain+'/api/v1/contents/vods/category/'+category+'/view', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json => {
                console.log(json.data);
                if (json.status === 'OK') {
                    this.setState({contents: json.data});
                    this.setState({loading: false});
                }
            });
        }
    }
    
    render() {   
      return (
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    { this.state.loading ?
                        <div style={{marginTop: 50}}>
                            <Loader />
                        </div>
                        :
                        <>
                        {
                            this.state.contents.content[1].status === 1 ?
                            <>
                            <div className="row">
                                <div className="col-12 p-1 pb-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3><Link to='/vods'  className="link">VOD</Link> / {this.state.contents.vodsCategory[0].name}</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control onChange={(e) => this.setState({search: e.target.value})} size="lg" type="text" placeholder="Search" className="pull-right w-md-50" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.search === '' ?
                                <div className="row">
                                {
                                    this.state.contents.vodsList.map((item) => (
                                        <div className="vodDiv p-1">
                                            <Link to={`/vod/${item.tag}`}  className="link">
                                                <img src={window.backendUrl+`/${item.image}`} alt={item.name} className="image" width="100%" />
                                                <div className="col-12">
                                                    <center><h6>{item.name}</h6></center>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                                </div>
                            :
                            <div className="row">
                                {
                                    this.state.contents.vodsList.filter( vods => vods.name.toLowerCase().includes((this.state.search).toLowerCase()) ).map((item) => (
                                        <div className="vodDiv p-1">
                                            <Link to={`/vod/${item.tag}`}  className="link">
                                                <img src={window.backendUrl+`/${item.image}`} alt={item.name} className="image" width="100%" />
                                                <div className="col-12">
                                                    <center><h6>{item.name}</h6></center>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                            }
                            </>
                        :
                            <div className="row">
                                <div className="col-md-6">
                                    <center><h2>Content Not Available!</h2></center>     
                                </div>
                            </div>
                        }
                        </>
                    }
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default VodCategory
