import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 
import { usePaystackPayment } from 'react-paystack';

import Header from "../components/Header";
import Footer from "../components/Footer";
import loadingImage from '../images/loader.gif';
import cardImage from '../images/pay.png';

class ChangePlan extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            planId: '',
            deviceNo: '',
            buttonLoading: false,
            showModal: false,
            packageName: '',
            packagePrice: '',
            packagePriceDollar: '',
            paystackPlanCodeNaira: '',
            paystackPlanCodeDollar: '',
            packageId: '',
            currentSubscription: '',
            paystackSubscriptions: [],
            displayCostNaira: '',
            displayCostDollar: '',
            loading: true
        }
    }

    componentDidMount(){
        fetch(window.backendDomain+'/api/v1/profile/subscriptions', {
            method: 'GET',
            headers: {
            'Content-type': 'application/json',
            'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json_sub => {
            //console.log(json_sub.data);
            if (json_sub.status === 'OK' && json_sub.data.length > 0) {
                this.setState({currentSubscription: json_sub.data[0][1].name});
            }
            this.setState({loading: false});
        });

        fetch('https://api.paystack.co/customer/'+window.contentsUser.user.email, {
            method: 'GET',
            //body: JSON.stringify(data),
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer '+window.paystackSecretKey
            }
        })
        .then(response => response.json())
        .then(json_sub_paystack => {
            //console.log(json_sub_paystack);
            this.setState({paystackSubscriptions: json_sub_paystack.data.subscriptions});
        });
    }

    render() { 
        const startDate = new Date()
        var date = new Date();
        const endDate = new Date(date.setMonth(date.getMonth()+parseInt(this.state.planId)));

        const handleClose = () => this.setState({showModal: false});
        
        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        switch (this.state.buttonLoading) {
            case false:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
            break;
            case true:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
            break;
            default:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        } 

        const handleDeviceChange = (value) => {
            this.setState({deviceNo: value});
            const planId = this.state.planId;
            const deviceNo = value;
            if(planId === "" || deviceNo === ""){
                
            } else {
                var tag = planId+"_"+deviceNo;
                fetch(window.backendDomain+'/api/v1/packages/view/'+tag, {
                    method: 'GET',
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    if (json.status === 'OK' && json.data.length > 0) {
                        this.setState({displayCostNaira: json.data[0].price});
                        this.setState({displayCostDollar: json.data[0].price_in_dollar});
                    } else {
                        this.setState({displayCostNaira: ''});
                        this.setState({displayCostDollar: ''});
                    }
                });
            }
        }

        const handleSubmit = () => {
            this.setState({buttonLoading: true});
            const planId = this.state.planId;
            const deviceNo = this.state.deviceNo;
            if(planId === "" || deviceNo === ""){
              Swal.fire({  
                title: 'ERROR',  
                type: 'error',
                icon: 'error',   
                text: 'Please, select plan and device!',  
              });
              this.setState({buttonLoading: false});
            } else {
                var tag = planId+"_"+deviceNo;
                fetch(window.backendDomain+'/api/v1/packages/view/'+tag, {
                    method: 'GET',
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    this.setState({buttonLoading: false});
                    //console.log(json);
                    if (json.status === 'OK') {
                        this.setState({packageName: json.data[0].name});
                        this.setState({packagePrice: json.data[0].price});
                        this.setState({packagePriceDollar: json.data[0].price_in_dollar});
                        this.setState({packageId: json.data[0]._id});
                        this.setState({paystackPlanCodeNaira: json.data[0].paystack_plan_code_naira});
                        this.setState({paystackPlanCodeDollar: json.data[0].paystack_plan_code_dollar});
                        this.setState({showModal: true});
                    } else {
                        Swal.fire({  
                            title: json.title,  
                            icon: 'error',   
                            text: json.message,  
                        });
                    }
                    this.setState({buttonLoading: false});
                });
            }
        } 
        
        const configNaira = {
            reference: (new Date()).getTime().toString()+Math.floor((Math.random() * 1000000000) + 1),
            email: window.contentsUser.user.email,
            amount: this.state.packagePrice * 100,
            currency: 'NGN',
            metadata: {
                name: window.contentsUser.user.name,
                phone: window.contentsUser.user.phone,
            },
            publicKey: window.paystackPublicKey,
        };
        const onSuccessNaira = (reference) => {
            //console.log(reference);
            // disable all users subscription on paystack
            for(var i = 0; i < this.state.paystackSubscriptions.length; i++){
                const data  = {
                    code: this.state.paystackSubscriptions[i]['subscription_code'],
                    token: this.state.paystackSubscriptions[i]['email_token']
                }
                fetch('https://api.paystack.co/subscription/disable', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_sub_disable => {
                    //console.log(json_sub_disable);
                });
            }
            const dataSub  = {
                packageId: this.state.packageId,
                startDate: startDate,
                endDate: endDate
            }
            fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                method: 'POST',
                body: JSON.stringify(dataSub),
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                //console.log(json_sub);
                if (json_sub.status === 'OK') {
                    this.setState({showModal: false});
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'success',   
                        text: json_sub.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'error',   
                        text: json_sub.message,  
                    });
                }
            });
            /*
            const data  = {
                customer: window.contentsUser.user.email,
                plan: this.state.paystackPlanCodeNaira,
                start_date: startDate
            }
            fetch('https://api.paystack.co/subscription', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+window.paystackSecretKey
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                const dataSub  = {
                    packageId: this.state.packageId,
                    startDate: startDate,
                    endDate: endDate
                }
                fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                    method: 'POST',
                    body: JSON.stringify(dataSub),
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub => {
                    //console.log(json_sub);
                    if (json_sub.status === 'OK') {
                        this.setState({showModal: false});
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'success',   
                            text: json_sub.message,  
                        });
                    } else {
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'error',   
                            text: json_sub.message,  
                        });
                    }
                });
            });
            */
        };
        const onCloseNaira = () => {
            //console.log('closed')
        }
        const PaystackHookNaira = () => {
            const initializePayment = usePaystackPayment(configNaira);
            return (
                <div>
                    <Button size="lg" variant="primary" type="submit" onClick={() => {
                        initializePayment(onSuccessNaira, onCloseNaira)
                    }} className="w-100">Naira Card</Button>
                </div>
            );
        };

        const configDollar = {
            reference: (new Date()).getTime().toString()+Math.floor((Math.random() * 1000000000) + 1),
            email: window.contentsUser.user.email,
            amount: this.state.packagePriceDollar * 100,
            currency: 'USD',
            metadata: {
                name: window.contentsUser.user.name,
                phone: window.contentsUser.user.phone,
            },
            publicKey: window.paystackPublicKey,
        };
        const onSuccessDollar = (reference) => {
            //console.log(reference);
            for(var i = 0; i < this.state.paystackSubscriptions.length; i++){
                const data  = {
                    code: this.state.paystackSubscriptions[i]['subscription_code'],
                    token: this.state.paystackSubscriptions[i]['email_token']
                }
                fetch('https://api.paystack.co/subscription/disable', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_sub_disable => {
                    //console.log(json_sub_disable);
                });
            }
            const dataSub  = {
                packageId: this.state.packageId,
                startDate: startDate,
                endDate: endDate
            }
            //console.log(dataSub)
            fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                method: 'POST',
                body: JSON.stringify(dataSub),
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                //console.log(json_sub);
                if (json_sub.status === 'OK') {
                    this.setState({showModal: false});
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'success',   
                        text: json_sub.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json_sub.title,  
                        icon: 'error',   
                        text: json_sub.message,  
                    });
                }
            });
            /*
            const data  = {
                customer: window.contentsUser.user.email,
                plan: this.state.paystackPlanCodeDollar,
                start_date: startDate
            }
            //console.log(data)
            fetch('https://api.paystack.co/subscription', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+window.paystackSecretKey
                }
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                const dataSub  = {
                    packageId: this.state.packageId,
                    startDate: startDate,
                    endDate: endDate
                }
                //console.log(dataSub)
                fetch(window.backendDomain+'/api/v1/packages/subscription/purchase/', {
                    method: 'POST',
                    body: JSON.stringify(dataSub),
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub => {
                    //console.log(json_sub);
                    if (json_sub.status === 'OK') {
                        this.setState({showModal: false});
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'success',   
                            text: json_sub.message,  
                        });
                    } else {
                        Swal.fire({  
                            title: json_sub.title,  
                            icon: 'error',   
                            text: json_sub.message,  
                        });
                    }
                });
            });
            */
        };
        const onCloseDollar = () => {
            //console.log('closed dollar')
        }
        const PaystackHookDollar = () => {
            const initializePayment = usePaystackPayment(configDollar);
            return (
                <div>
                    <Button size="lg" variant="primary" type="submit" onClick={() => {
                        initializePayment(onSuccessDollar, onCloseDollar)
                    }} className="w-100">Dollar Card</Button>
                </div>
            );
        };
        //console.log('sub ', this.state.paystackSubscriptions)
        return (
            <div>
                <Modal show={this.state.showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change plan - Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.currentSubscription === this.state.packageName ?
                                <center>
                                    <h5>You already have {this.state.packageName} package!</h5>
                                </center>
                            :
                            <center>
                                <p><b>Current Package:</b> {this.state.currentSubscription}</p>
                                <p><b>New Package:</b> {this.state.packageName}</p>
                                <p><b>Amount:</b> &#8358;{this.state.packagePrice} (${this.state.packagePriceDollar})</p>
                                <hr />
                                <h5>Please, choose a payment currency</h5>
                                <p>Credit or Debit Card <img src={cardImage} alt="Payment Options" width="150" /></p>
                                <div className="row">
                                    <div className="col-6">
                                        <PaystackHookNaira />
                                    </div>
                                    <div className="col-6">
                                        <PaystackHookDollar />
                                    </div>
                                </div>
                                
                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <p>By clicking on the payment button, you agreed with <a href="https://oyawatch.com/terms-of-use/" target="_blank" rel="noreferrer noopener" >Terms and Conditions</a>, <a href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="https://oyawatch.com/cookie-policy/" target="_blank" rel="noreferrer noopener">Cookie Policy</a></p>
                                        <center><p>Secure Server <i className="fa fa-lock" style={{color: 'green'}}></i></p></center>
                                    </div>
                                </div>
                            </center>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-div-2">
                                            <center><h4>Change Subscription</h4></center>
                                            <hr />
                                            {
                                                this.state.currentSubscription.length === 0 && this.state.loading === false?
                                                    <center>
                                                        <h6>You don't have any subscription at the moment, kindly subscribe!</h6>
                                                    </center>
                                                :
                                                <>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Plan</Form.Label>
                                                        <select className="form-control" onChange={(e) => this.setState({planId: e.target.value})}>
                                                            <option value="">Select plan</option>
                                                            <option value="1">1 month</option>
                                                            <option value="3">3 months</option>
                                                            <option value="6">6 months</option>
                                                            <option value="12">1 year</option>
                                                        </select>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Label>Devices</Form.Label>
                                                        <select className="form-control" onChange={(e) => handleDeviceChange(e.target.value)}>
                                                            <option value="">Select device</option>
                                                            <option value="1">1 device</option>
                                                            <option value="2">2 devices</option>
                                                            <option value="3">3 devices</option>
                                                            <option value="4">4 devices</option>
                                                            <option value="5">5 devices</option>
                                                        </select>
                                                    </Form.Group>
                                                    <center>
                                                        { this.state.displayCostNaira !== '' && this.state.displayCostDollar !== '' ?
                                                            <Form.Label>Subscription Cost + VAT charges: &#8358;{this.state.displayCostNaira} (${this.state.displayCostDollar})</Form.Label>
                                                        :
                                                            <></>
                                                        }
                                                    </center>
                                                    {submitButton}
                                                </>
                                            }
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default ChangePlan
