import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Swal from "sweetalert2"; 
import { Navbar, Nav, Container } from 'react-bootstrap';
import i18n from 'i18n-js';

//import Header from "../components/HeaderOut";
//import Footer from "../components/Footer";

import Logo from "../images/logo.png";
import loadingImage from '../images/loader.gif';
import Background from '../images/login_bg.png';

class Login extends Component {
    constructor(props) {
      super(props)

      this.state = {
        email: '',
        password: '',
        deviceName: 'Web Browser',
        deviceId: 'fcb9ef9019deac5377532c3824c3061c',
        buttonLoading: false,
        locationApiUrl: '',
      }
    }
    
    componentDidMount(){
      // //fetch('https://ipapi.co/json/', {
      // fetch('https://ipinfo.io/?token=d16f793fc3ce26', {
      //   method: 'GET',
      //   headers: {
      //       'Content-type': 'application/json'
      //   }
      // })
      // .then(response => response.json())
      // .then(json => {
      //     //console.log(json);
      //     //console.log(Object.keys(json).length>3);
      //     if(Object.keys(json).length>3){
      //         //localStorage.setItem('visitor', json.country_code);
      //         localStorage.setItem('visitor', json.country);
      //     }
      // });
      // get location api url
      console.log(global.backendDomain);
      fetch(global.backendDomain+'/api/v1/profile/location-apis', {
        method: 'GET',
        //body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json',
          'auth-token': global.userToken
        }
      })
      .then(response => response.json())
      .then(json => {
        //console.log(json);
        if (json.status === 'OK') {
          this.setState({locationApiUrl: json.data.url});
        }
      });

    }
    
    render() {
      const d = new Date();
      let year = d.getFullYear();
      if(localStorage.getItem('token')){
        //return <Redirect to={'/'} />
      }
      if(this.state.locationApiUrl !== ''){
        fetch(this.state.locationApiUrl, {
          method: 'GET',
          headers: {
              'Content-type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(json => {
          //console.log(json);
          //console.log(Object.keys(json).length);
          if(Object.keys(json).length>3){
            //var country = json.country_code;
            let substring1 = 'ipregistry.co';
            let substring2 = 'ip-api.com';
            if(this.state.locationApiUrl.includes(substring1) === true){
              let country = json.location.country.code;
              localStorage.setItem('visitor', country);
            } else if(this.state.locationApiUrl.includes(substring2) === true){
              let country = json.countryCode;
              localStorage.setItem('visitor', country);
            } else {
              let country = json.country;
              localStorage.setItem('visitor', country);
            }
            //AsyncStorage.setItem('date', today);
          }
        });
      }
      const handleSubmit = () => {
        this.setState({buttonLoading: true});
        const email = (this.state.email).toLowerCase();
        const password = this.state.password;
        if(email === "" || password === ""){
          Swal.fire({  
            title: `${i18n.t('7')}`,  
            icon: 'error',   
            text: `${i18n.t('8')}`,  
          });
          this.setState({buttonLoading: false});
        } else {
          const data  = {
            email: email,
            password: password,
            deviceName: this.state.deviceName,
            deviceId: this.state.deviceId
          }
          fetch(window.backendDomain+'/api/v1/auth/login', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
              if (json.data.deviceRemove) {
                Swal.fire({  
                  position: 'center',  
                  icon: 'success',  
                  title: '',  
                  text: 'Login Successful '+json.data.removeDevice+' logout', 
                  showConfirmButton: false,  
                  timer: 4000  
                });
                window.location="/";
              } else {
                localStorage.setItem('token', json.data.token);
                Swal.fire({  
                  position: 'center',  
                  icon: 'success',  
                  title: '',  
                  text: 'Login Successful', 
                  showConfirmButton: false,  
                  timer: 4000  
                });
                window.location="/";
              }
            } else {
              Swal.fire({  
                title: json.title,  
                icon: 'error',   
                text: json.message,  
              });
            }
            this.setState({buttonLoading: false});
          });
        }
      }
      
      let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('3')}</Button>;
      switch (this.state.buttonLoading) {
        case false:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('3')}</Button>;
          break;
        case true:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /></Button>
          break;
        default:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >{i18n.t('3')}</Button>;
      }
      return (
        <div style={{backgroundImage: `url(${Background})`, height:"100vh"}}>
          <Navbar collapseOnSelect expand="lg" fixed="top" variant="dark" style={{backgroundColor: 'transparent'}}>
            <Container>
            <Navbar.Brand><Link to="/"><img style={{opacity: 1}} src={Logo} alt="OyaWatch Logo" height="50px" /></Link></Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            
            </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="form-div" style={{backgroundColor: 'black', opacity: 0.75}}>
                    <center><h4>{i18n.t('3')}</h4></center>
                    <hr />
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{i18n.t('1')}</Form.Label>
                      <Form.Control size="lg" type="email" placeholder={i18n.t('11')} onChange={(e) => this.setState({email: e.target.value})} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>{i18n.t('2')}</Form.Label>
                      <Form.Control size="lg" type="password" placeholder={i18n.t('2')} onChange={(e) => this.setState({password: e.target.value})} />
                    </Form.Group>
                    {submitButton}
                    <Form.Group className="mt-3">
                      <div className="row">
                        <div className="col-6">
                          <Link to="/forgot" className="link"><u>{i18n.t('4')}</u></Link>
                        </div>
                        <div className="col-6">
                          <Link to="/register" className="link pull-right"><u>{i18n.t('6')}</u></Link>
                        </div>
                      </div>
                    </Form.Group>
                    <center>
                      <br />
                      <div className="col-12">
                        <p style={{fontSize: 12, fontWeight: 'bold'}}><a href="https://intradot.com.ng" className="link">INTRADOT LTD</a> <i className="fa fa-copyright"></i> Copyright {year} </p>
                      </div>
                    </center>
                  </div>   
                </div>
              </div>
            </div>
          </div>
          <br />  
        </div>
      );
    }        
}

export default Login
