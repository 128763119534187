import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';

import Header from "../components/HeaderOut";
import Footer from "../components/Footer";


class Register extends Component {
    render() {  

      return (
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <center><h3>Terms and Conditions</h3></center>
                            <br />
                            <p>Welcome to OyaWatch TV. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us&nbsp;with  support@oyawatch.com</p>



<p>These Terms of Use (the “Terms”) are a binding contract between
you and&nbsp;<strong>IntraDot Limited </strong>a company incorporated
according to the laws of the Federal Republic of Nigeria, with Registration number RC 1441588.
The proprietor of the trademark, business name and the intellectual property of
the brand (“OyaWatch, OyaWatch TV” “we”, “our”, and “us”).&nbsp;<strong>You must agree to and accept all of the Terms, or you may not
access or use the Services.&nbsp;</strong>Your use of or access to the
Services in any way means that you agree to all of these Terms, and these Terms
will remain in effect while you use the Services. These Terms include the
provisions in this document, as well as those in the&nbsp; <a className="link" href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener"><u>Privacy
Policy </u></a>&nbsp;and&nbsp; <a className="link" href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener"><u>Copyright Dispute Policy </u></a>,
and if you operate a private channel, our Private Channel Agreement (which
shall control in the event of conflict with these Terms).</p>



<p><strong>WARNING: THE UNAUTHORIZED REPRODUCTION OR DISTRIBUTION OF COPYRIGHTED WORKS AVAILABLE THROUGH OUR SERVICES IS ILLEGAL AND MAY BE PROSECUTED UNDER CRIMINAL OR CIVIL LAWS OF THE FEDERAL REPUBLIC OF NIGERIA</strong> </p>



<p><strong>What
about my privacy?</strong><strong></strong></p>



<p>OyaWatch takes the privacy of its users very seriously. For the
current OyaWatch Privacy Policy, please&nbsp; <a className="link" href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener"> <u>click here </u></a>.</p>



<p><strong>What are the basics of using OyaWatch TV?</strong></p>



<p>You may
be required to sign up for an account, and select a password and user name (“OyaWatch
User ID”). You promise to provide us with accurate, complete, and updated
registration information about yourself. You may not select as your OyaWatch
User ID a name that you don’t have the right to use, or another person’s name
with the intent to impersonate that person. You may not transfer your account
to anyone else without our prior written permission. You will keep all your
registration information accurate and current, and if your account credentials
are compromised or your account is misused, contact us at the address
below.&nbsp;</p>



<p>You must
be 13 years of age, or the age of majority in your jurisdiction in order to use
the Services. Individuals under the age of 13, or applicable age of majority,
may utilize the Services only under the supervision of a parent or legal
guardian that agrees to these Terms of Use. If you’re agreeing to these Terms
on behalf of an organization or entity, you represent and warrant that you are
authorized to agree to these Terms on that organization or entity’s behalf and
bind them to these Terms (in which case, the references to “you” and “your” in
these Terms, except for in this sentence, refer to that organization or
entity).</p>



<p>Except as
set forth in a written agreement between you and OyaWatch, you may only use the
Services for your own internal, personal, non-commercial use, and not on behalf
of or for the benefit of any third party, and only in a manner that complies
with all laws that apply to you. If your use of the Services is prohibited by
applicable laws, then you may not use the Services. We can’t and won’t be
responsible for your using the Services in a way that breaks the law.</p>



<p>You may
not share your account or password with anyone, and you must protect the
security of your account and your password. You’re responsible for any activity
associated with your account.</p>



<p>You
represent, warrant, and agree that you did not and will not contribute any
Content, including your User Content (each of those terms is defined below), or
otherwise use or interact with the Services in a manner that:</p>



<p>(a)
&nbsp;Infringes or violates the intellectual property rights or any other
rights of anyone else (including OyaWatch);</p>



<p>(b)
&nbsp;Violates or encourages the violation of any law or regulation;</p>



<p>(c)
&nbsp;Is harmful, fraudulent, deceptive, threatening, harassing, defamatory,
obscene, or otherwise objectionable;</p>



<p>(d)
&nbsp;Jeopardizes the security of your OyaWatch account or anyone else’s (such
as allowing someone else to log in to the Services as you);</p>



<p>(e)
&nbsp;Attempts, in any manner, to obtain the password, account, or other
security information from any other user;</p>



<p>(f)
&nbsp;interferes with or damages the Services, including, without limitation,
through the use of viruses, Trojan horses, harmful code, denial of service
attacks, packet or IP spoofing, forged routing or email address information or
similar methods or technology, or disobey any requirements, procedures,
policies, or regulations of networks connected to our Service;&nbsp;</p>



<p>(g)
&nbsp;Runs Maillist, Listserv, any form of auto-responder or “spam” on the
Services, or any processes that run or are activated while you are not logged
into the Services, or that otherwise interfere with the proper working of the
Services (including by placing an unreasonable load on the Services’
infrastructure, e.g. via a DDoS attack);</p>



<p>(h)
“Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to
the Services or Content (through use of manual or automated means), except in
connection with reasonable indexing by search engines;</p>



<p>(i)
Copies or stores any significant portion of the Content;</p>



<p>(j)
Decompiles, reverse engineers, or otherwise attempts to obtain the source code
or underlying ideas or information of or relating to the Services;&nbsp;</p>



<p>(k) Interferes
with any third party’s ability to use or enjoy the Service; or&nbsp;</p>



<p>(l)
Assists any third party in engaging in any activity prohibited by these Terms.</p>



<p>A
violation of these Terms is grounds for termination of your right to use or
access the Services, in addition to OyaWatch’s rights and remedies available at
law.</p>



<p><strong>What
are my rights with respect to the Services?</strong><strong></strong></p>



<p>We
provide our Services for entertainment, informational and/or promotional
purposes only. We grant you, subject to your compliance with these Terms, a
non-exclusive, non-assignable, non-sublicensable, limited license to use our
Services and view the Content for your personal, non-commercial use only. The
materials displayed or performed or available on or through the Services,
including, but not limited to, text, graphics, data, articles, photos, images,
illustrations, User Content (defined below), and so forth (all of the
foregoing, the “Content”) are protected by copyright and/or other intellectual
property laws, and are either owned by OyaWatch or licensed to OyaWatch by a
third party.&nbsp;</p>



<p>You
understand that OyaWatch owns the Services. In certain cases, we may provide
our Services as part of third party services, applications or websites, or we
may embed Content from third parties in our Services. In all cases, you may not
modify, publish, transmit, participate in the transfer or sale of, reproduce
(except as expressly provided in this Section), create derivative works based
on, or otherwise exploit any of the Services. You promise to abide by all
copyright notices, trademark rules, information, and restrictions contained in
any Content you access through the Services, and that you won’t use, copy,
reproduce, modify, translate, publish, broadcast, transmit, distribute,
perform, upload, display, license, sell or otherwise exploit for any purpose
any Content not owned by you, (i) without the prior consent of the owner of
that Content or (ii) in a way that violates someone else’s (including OyaWatch’s)
rights.&nbsp;</p>



<p>We may
from time to time give you the ability to link, embed, download, or copy
certain Content pursuant to these Terms. If you do so, you may not modify,
build upon or block any portion or functionality of the Content, including
links back to our Service or any DRM functionality protecting the Content.
Please remember that just because this functionality exists, doesn’t mean that
all the licenses and restrictions above don’t apply – they do! You further
agree to not place embedded Content in any location or on any site or service
that contains or promotes unlawful, inappropriate, pornographic, obscene,
defamatory, harassing, hateful, discriminatory, or similar unlawful or immoral
conduct. In addition, you must post your own terms and privacy policy
consistent with applicable law, your use of our Content, these Terms, and our
Privacy Policy.&nbsp;</p>



<p>You also
agree that we have the exclusive right to serve ads and collect information
with respect to Content available through the Services, unless we have a
separate written agreement with you. Accordingly, you may not advertise on any
page containing our Content without our prior express written consent. For the
avoidance of doubt, you are expressly prohibited from stream-capturing,
reproducing, rebroadcasting, retransmitting, or otherwise performing, using,
copying, modifying, distributing, replicating, translating, or creating any
other derivative work of any kind based on the Content, using any form of data
extraction or data mining process or procedure on the Content, or otherwise
exploiting or using the Content, commercially or otherwise, without our prior
written permission.</p>



<p><strong>What
licenses do I grant when I use the Services?</strong><strong></strong></p>



<p>Anything
you post, upload, share, store, or otherwise provide through the Services is
your “User Content.” Please note, some User Content may be viewable by other
users. In order to display your User Content on the Services, and to allow
other users to enjoy it, you hereby grant OyaWatch a worldwide, royalty-free,
perpetual, irrevocable (except as expressly provided in these Terms or the
Privacy Policy), non-exclusive, assignable, and sublicensable license to
translate, modify, adapt, perform, reproduce and otherwise use your User
Content, in connection with &nbsp;our provision of the Services. This is a
license only – your retain ownership and reserve all other rights in your User
Content unless otherwise specified in writing. Please note, if you operate a
private channel on OyaWatch, the Private Channel Agreement applies to the User
Content you provide and other matters with respect to your operation of that
private channel.</p>



<p>If you
share User Content publicly on the Services (“Public User Content”), then you
acknowledge and agree that such Public User Content will be made available to
all other users of the Services and the general public, and that anyone may
view, copy, distribute, perform or otherwise use your Public User
Content.&nbsp;</p>



<p>Finally,
you understand and agree that OyaWatch, in performing the required technical
steps to provide the Services to our users (including you), may need to make
changes to your User Content to conform and adapt those User Content to the
technical requirements of connection networks, devices, services, or media, and
the foregoing licenses include the rights to do so.</p>



<p><strong>What
if I see something on the Services that infringes my copyright?</strong><strong></strong></p>



<p>You may have heard of the Nigerian Copyright Act (NCA), as it
relates to online service providers, like OyaWatch, being asked to remove
material that allegedly violates someone’s copyright. We respect others’
intellectual property rights, and we will delete or disable Content alleged to
be infringing, and terminate the accounts of repeat alleged infringers as set
forth in NCA policy. </p>



<p><strong>What
are the parties’ responsibilities and obligations regarding the Services?</strong><strong></strong></p>



<p>Our
Services make available Content that is curated by third parties, including
professional Content and User Content submitted by other users. OyaWatch does
not moderate or control any specific Content available through the Services.
Any Content and the information, ideas, opinions, recommendations, or any other
aspects of that Content are the sole responsibility of the party providing such
Content. You access all Content at your own risk, and we will not be liable for
any damages or loss you might suffer in connection with or arising from the
Services or Content unless and to the extent expressly stated in the Warranty
section, below. &nbsp;Note, that while we require our users comply with these
Terms, we can’t guarantee the identity of our users and which users gain access
to the Services or provide Content. Similarly, we do not represent or warrant
that any Content is or will be compliant with these Terms.</p>



<p>You are
responsible for all Content you contribute, in any manner, to the Services, and
you represent and warrant you have all rights necessary to do so for the
purpose which it was provided. &nbsp;The Services may contain links or
connections to third party websites or services that are not owned or
controlled by OyaWatch. When you access third party websites or use third party
services, you accept that there are risks in doing so, and that OyaWatch is not
responsible for such risks. We encourage you to be aware when you leave the
Services and to read the terms and conditions and privacy policy of each third
party website or service that you visit or utilize.</p>



<p>OyaWatch
has no control over, and assumes no responsibility for, the content, accuracy,
privacy policies, or practices of or opinions expressed in any third party
websites or by any third party that you interact with through the Services. In
addition, OyaWatch will not and cannot monitor, verify, censor or edit the
content of any third party site or service. By using the Services, you release
and hold us harmless from any and all liability arising from your use of any
third party website or service.</p>



<p>Your
interactions with organizations and/or individuals found on or through the
Services, including payment and delivery of goods or services, and any other
terms, conditions, warranties or representations associated with such dealings,
are solely between you and such organizations and/or individuals. You should
conduct whatever investigation you feel necessary or appropriate before
proceeding with any online or offline transaction with any of these third
parties. You agree that OyaWatch shall not be responsible or liable for any
loss or damage of any sort incurred as the result of any such dealings.</p>



<p>If there
is a dispute between participants on our Services, or between users and any
third party, you agree that OyaWatch is under no obligation to become involved.
In the event that you have a dispute with one or more other users, you release OyaWatch,
its officers, employees, agents, and successors from claims, demands, and
damages of every kind or nature, known or unknown, suspected or unsuspected,
disclosed or undisclosed, arising out of or in any way related to such disputes
and/or our Services.&nbsp;</p>



<p><strong>What
about promotions?</strong><strong></strong></p>



<p>All
promotional offers, sweepstakes, contests, giveaways and other promotions
(“Promotions”) we may offer from time to time are subject to these Terms and
any supplemental terms we disclose. Generally, with or without notice, we
reserve the right to modify, suspend, cancel or terminate any Promotion in
appropriate circumstances, including to extend or resume the stated entry
period, disqualify any participant or entry, or award prizes in an alternate
manner. You are responsible for all costs, expenses or taxes associated with
your participation and/or receipt of any prizes or awards. We may condition
your participation or receipt of a prize/award on the execution of a release
and/or other agreements. By entering any of the Promotions, you automatically
consent to and grant us the right to use of your name, image, likeness,
statements, biographical information and other information about you for
publicity, advertising and promotional purposes, all without additional
permission from or compensation to you.</p>



<p><strong>Can
I provide suggestions or feedback?</strong><strong></strong></p>



<p>We welcome your comments and feedback about our Services. All
information and materials submitted to OyaWatch through the Services or
otherwise, such as any comments, feedback, ideas, questions, designs, data or
the like regarding or relating to the Services or the business of OyaWatch
(collectively, “<strong>Feedback</strong>“), will be considered
NON-CONFIDENTIAL and NON-PROPRIETARY with regard to you, but OyaWatch reserves
the right to treat any such Feedback as the confidential information of OyaWatch.&nbsp;</p>



<p>By
submitting Feedback to OyaWatch, you assign to OyaWatch and its officers,
directors, employees, parents, partners, successors, agents, distribution
partners, affiliates, subsidiaries and their related companies (the “OyaWatch
Parties”) free of charge, all worldwide rights, title and interest in all
copyrights and other intellectual property rights in such Feedback. The OyaWatch
Parties will be entitled to use any Feedback you submit, and any ideas,
concepts, know-how or techniques contained in any such Feedback, for any
purpose whatsoever, including but not limited to developing, manufacturing and
marketing products and services using such Feedback without restriction and
without compensating you in any way. You are responsible for the information
and other content contained in any Feedback you submit to us, including,
without limitation, its truthfulness and accuracy.</p>



<p><strong>Will
OyaWatch ever modify the Services?</strong><strong></strong></p>



<p>We’re always
trying to improve the Services, so they may change over time. We may suspend or
discontinue any part of the Services, or we may introduce new features or
impose limits on certain features or restrict access to parts or all of the
Services. Similarly, we reserve the right to remove any Content from the
Services at any time, for any reason (including, but not limited to, if someone
alleges you contributed that Content in violation of these Terms), in our sole
discretion, and without notice.</p>



<p><strong>Will
these Terms ever change?</strong><strong></strong></p>



<p>We
reserve the right to change these Terms at any time, and will provide notice to
you and obtain your consent where required by law.</p>



<p>If you
don’t agree with the new Terms, you are free to reject them; unfortunately,
that means you will no longer be able to use the Services. If you access or use
the Services after a change to the Terms is effective, that means you agree to
all of the changes.</p>



<p>Except
for changes by us as described here, no other amendment or modification of
these Terms will be effective unless in writing and signed by both you and us.</p>



<p><strong>Does OyaWatch TV cost anything?</strong></p>



<p>The OyaWatch
Services are currently free, but we reserve the right to charge for certain or
all Services in the future. We will notify you before any Services you are
currently using begin carrying a fee, and if you wish to continue using such
Services, you must pay all applicable fees for Services and not Content, such
as&nbsp; maintenance and updates of our APPS “Applications
(software)&#8221; on all the platforms we currently offer. </p>



<p><strong>What if I want to stop using OyaWatch TV?</strong></p>



<p>You’re free to do that at any time, by&nbsp; <a className="link" target="_blank" rel="noreferrer noopener" href="mailto:support@oyawatch.com"><u>contacting us here </u></a>;
please refer to our&nbsp; <a className="link" href="https://oyawatch.com/policy/" target="_blank" rel="noreferrer noopener"><u>Privacy Policy </u></a>,
as well as the licenses above, to understand how we treat information you
provide to us after you have stopped using our Services.</p>



<p>OyaWatch
is also free to terminate (or suspend access to) your use of the Services or
your account, for any reason in our discretion, including but not limited to
your breach of these Terms. OyaWatch has the sole right to decide whether you
are in violation of any of the restrictions set forth in these Terms.</p>



<p>Account termination may result in destruction of any Content
associated with your account, so keep that in mind before you decide to
terminate your account. If you have deleted your account by mistake, contact us
immediately&nbsp; <a className="link" href="http://support@oyawatch.com" target="_blank" rel="noreferrer noopener"><u>here </u></a>&nbsp;– we will try to help, but
unfortunately, we can’t promise that we can recover or restore anything.</p>



<p><strong>I
use the OyaWatch App available via the Apple App Store – should I know anything
about that?</strong><strong></strong></p>



<p>These
Terms apply to your use of all the Services, including the iPhone and iPad
applications available via the Apple, Inc. (“Apple”) App Store (the
“Application”), but the following additional terms also apply to the
Application:</p>



<p>(a) Both
you and OyaWatch acknowledge that the Terms are concluded between you and OyaWatch
only, and not with Apple, and that Apple is not responsible for the Application
or the Content;</p>



<p>(b) The
Application is licensed to you on a limited, non-exclusive, non-transferable,
non-sublicensable basis, solely to be used in connection with the Services for
your private, personal, non-commercial use, subject to all the terms and
conditions of these Terms as they are applicable to the Services;</p>



<p>(c) You
will only use the Application in connection with an Apple device that you own
or control;</p>



<p>(d) You
acknowledge and agree that Apple has no obligation whatsoever to furnish any
maintenance and support services with respect to the Application;</p>



<p>(e) In
the event of any failure of the Application to conform to any applicable
warranty, including those implied by law, you may notify Apple of such failure;
upon notification, Apple’s sole warranty obligation to you will be to refund to
you the purchase price, if any, of the Application;</p>



<p>(f) You
acknowledge and agree that OyaWatch, and not Apple, is responsible for
addressing any claims you or any third party may have in relation to the
Application;</p>



<p>(g) You
acknowledge and agree that, in the event of any third party claim that the
Application or your possession and use of the Application infringes that third
party’s intellectual property rights, OyaWatch, and not Apple, will be
responsible for the investigation, defense, settlement and discharge of any
such infringement claim;</p>



<p>(h) You
represent and warrant that you are not located in a country subject to a U.S.
Government embargo, or that has been designated by the U.S. Government as a
“terrorist supporting” country, and that you are not listed on any U.S.
Government list of prohibited or restricted parties;</p>



<p>(i) Both
you and OyaWatch acknowledge and agree that, in your use of the Application,
you will comply with any applicable third party terms of agreement which may
affect or be affected by such use; and</p>



<p>(j) Both
you and OyaWatch acknowledge and agree that Apple and Apple’s subsidiaries are
third party beneficiaries of these Terms, and that upon your acceptance of
these Terms, Apple will have the right (and will be deemed to have accepted the
right) to enforce these Terms against you as the third party beneficiary
hereof.</p>



<p><strong>What
else do I need to know?</strong><strong></strong></p>



<p><strong><em>Warranty
Disclaimer</em></strong><strong></strong></p>



<p>OyaWatch
does not make any representations or warranties concerning the Services,
including without limitation any Content contained in or accessed through the
Services, and we will not be responsible or liable for the accuracy, copyright
compliance, legality, or decency of material contained in or accessed through
the Services. We make no representations or warranties regarding the Services,
including any suggestions or recommendations of services or content offered or
available through the Services. THE SERVICES AND CONTENT ARE PROVIDED ON AN
“AS-IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE
OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY
NOT APPLY TO YOU.</p>



<p><strong><em>Limitation
of Liability</em></strong><strong></strong></p>



<p>TO THE
FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO
LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY,
OR OTHERWISE) SHALL OYAWATCH BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A)
ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND,
INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY
OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
AGGREGATE, IN EXCESS OF THE GREATER OF (I) N10,000.00 OR (II) THE AMOUNTS PAID
BY YOU TO OYAWATCH IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH
PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (III) ANY MATTER BEYOND OUR
REASONABLE CONTROL.&nbsp;YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF,
RELATING TO, OR IN ANY WAY CONNECTED WITH ANY OF OUR SERVICES OR THESE TERMS
MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE,
SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED. SOME STATES DO NOT ALLOW THE
EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS
MAY NOT APPLY TO YOU.&nbsp;</p>



<p><strong><em>Indemnity</em></strong><strong></strong></p>



<p>To the
fullest extent allowed by applicable law, you agree to indemnify and hold OyaWatch,
its affiliates, officers, agents, employees, and partners harmless from and
against any and all claims, liabilities, damages (actual and consequential),
losses and expenses (including reasonable attorneys’ fees) arising from or in
any way related to any third party claims relating to (a) your use of the
Services (including any actions taken by a third party using your account), and
(b) your violation of these Terms.</p>



<p><strong><em>Assignment</em></strong><strong></strong></p>



<p>You may
not assign, delegate or transfer these Terms or your rights or obligations
hereunder, or your Services account, in any way (by operation of law or
otherwise) without OyaWatch’s prior written consent. We may transfer, assign,
or delegate these Terms and our rights and obligations without consent.</p>



<p><strong><em>Choice
of Law; Arbitration</em></strong><strong></strong></p>



<p>These
Terms are governed by and will be construed under the laws of the State of Kano,
without regard to the conflicts of laws provisions thereof. Any dispute arising
from or relating to the subject matter of these Terms shall be finally settled
in Kano State High Court, Kano, in English, in accordance with the Arbitration
Rules and Procedures of Judicial Arbitration then in effect, by one commercial
arbitrator with substantial experience in resolving intellectual property and
commercial contract disputes, who shall be selected from the appropriate list
of arbitrators in accordance with such Rules. Judgment upon the award rendered
by such arbitrator may be entered in any court of competent jurisdiction.
Notwithstanding the foregoing obligation to arbitrate disputes, each party
shall have the right to pursue injunctive or other equitable relief at any
time, from any court of competent jurisdiction. For all purposes of this
Agreement, the parties consent to exclusive jurisdiction and venue in the state
or federal courts located in, respectively, Kano, Kano State, or the Federal
High Court in Kano State.</p>



<p><strong><em>Taxes</em></strong><strong></strong></p>



<p>You will
be responsible for paying, withholding, filing, and reporting all taxes,
duties, and other governmental assessments associated with your activity in
connection with the Services, provided that the OyaWatch may, in its sole
discretion, do any of the foregoing on your behalf or for itself as it sees
fit.&nbsp;</p>



<p><strong><em>Waiver</em></strong><strong></strong></p>



<p>The
failure of either you or us to exercise, in any way, any right herein shall not
be deemed a waiver of any further rights hereunder.&nbsp;</p>



<p><strong><em>Severability</em></strong><strong></strong></p>



<p>If any
provision of these Terms is found to be unenforceable or invalid, that
provision will be limited or eliminated, to the minimum extent necessary, so
that these Terms shall otherwise remain in full force and effect and
enforceable.&nbsp;</p>



<p><strong><em>Survival</em></strong><strong></strong></p>



<p>Provisions
that, by their nature, should survive termination of these Terms shall survive
termination. By way of example, all of the following will survive termination:
any obligation you have to pay us or indemnify us, any limitations on our
liability, any terms regarding ownership or intellectual property rights, and
terms regarding disputes between us.</p>



<p><strong><em>Integration</em></strong><strong></strong></p>



<p>You and OyaWatch
agree that these Terms are the complete and exclusive statement of the mutual
understanding between you and OyaWatch, and that it supersedes and cancels all
previous written and oral agreements, communications and other understandings
relating to the subject matter of these Terms, and that all modifications to
these Terms must be in a writing signed by both parties (except as otherwise
provided herein).&nbsp;</p>



<p><strong><em>No
Relationship</em></strong><strong></strong></p>



<p>No agency,
partnership, joint venture, or employment is created as a result of these Terms
and you do not have any authority of any kind to bind OyaWatch in any respect
whatsoever.&nbsp;</p>



<p><strong><em>No
Third Party Beneficiaries</em></strong><strong></strong></p>



<p>Except as
expressly set forth in the section above regarding the Apple Application, you
and OyaWatch agree there are no third party beneficiaries intended under these
Terms.</p>



<p><strong><em>International
Access</em></strong><strong></strong></p>



<p>If you
access the Services from locations outside of the Nigeria you do so on your own
initiative and at your own risk, and you are solely responsible for compliance
with local laws, if and to the extent local laws are applicable. You further
agree to comply with any and all export control regulations that may apply to
your use of the Services and any Content.</p>



<p><strong><em>Electronic
Communication.</em></strong><strong></strong></p>



<p>From time
to time, we may communicate with you about the Services, your rights,
promotions, or other matters. You hereby consent to receive electronic
communications from us and further agree that any notices, agreements,
disclosures, and other communications that we send to you electronically will
satisfy any applicable legal notification requirements. We recommend that you
keep a copy of any electronic communications we send to you for your records.</p>



<p><strong><em>Contact</em></strong><strong></strong></p>



<p>If you have a question, concern or complaint about these Terms, you can contact us by emailing&nbsp;support@oyawatch.com</p>
	
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default Register
