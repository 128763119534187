import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//import Modal from 'react-bootstrap/Modal';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 

import Header from "../components/Header";
import Footer from "../components/Footer";
import loadingImage from '../images/loader.gif';
import Loader from "../components/Loader";
//import cardImage from '../images/pay.png';

class CancelSubscription extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            buttonLoading: false,
            reason: '',
            loading: true,
            subscription: [],
            paystackSubscriptions: []
        }
    }

    componentDidMount(){
        let { id } = this.props.match.params;
        //console.log(id);
        if(this.state.loading === true){
            fetch(window.backendDomain+'/api/v1/profile/subscriptions', {
                method: 'GET',
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then(json_sub => {
                if (json_sub.status === 'OK') {
                    //console.log(json_sub);
                    for(var i=0; i < json_sub.data.length; i++){
                        if(json_sub.data[i][0].subscriptionId === id){
                            this.setState({subscription: json_sub.data[i]});
                            this.setState({loading: false});
                        }
                    }
                }
            });
        }
        fetch('https://api.paystack.co/customer/'+window.contentsUser.user.email, {
            method: 'GET',
            //body: JSON.stringify(data),
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer '+window.paystackSecretKey
            }
        })
        .then(response => response.json())
        .then(json_sub_paystack => {
            //console.log(json_sub_paystack.data.subscriptions);
            this.setState({paystackSubscriptions: json_sub_paystack.data.subscriptions});
            
        });
    }

    render() { 
        //console.log(this.state.paystackSubscriptions);
        var subscription_code='', email_token='';
        if(this.state.loading === false && this.state.paystackSubscriptions.length > 0){
            //console.log(this.state.subscription)
            var dateOyawatch = this.state.subscription[0].startDate;
            //console.log(dateOyawatch)
            for(var i = 0; i < this.state.paystackSubscriptions.length; i++){
                var datePaystack = this.state.paystackSubscriptions[i].createdAt;
                if((dateOyawatch.substring(0,10) === datePaystack.substring(0,10))){
                    subscription_code = this.state.paystackSubscriptions[i]['subscription_code'];
                    email_token = this.state.paystackSubscriptions[i]['email_token'];
                }
            }
        }
        //console.log(subscription_code);
        //console.log(email_token);
        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit(subscription_code, email_token)} >Submit</Button>;
        switch (this.state.buttonLoading) {
            case false:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit(subscription_code, email_token)} >Submit</Button>;
            break;
            case true:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
            break;
            default:
                submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit(subscription_code, email_token)} >Submit</Button>;
        } 
        const handleSubmit = (subscription_code, email_token) => {
            this.setState({buttonLoading: true});
            const reason = this.state.reason;
            if(reason === ""){
              Swal.fire({  
                title: 'ERROR',  
                icon: 'error',   
                text: 'Please, select a reason!',  
              });
              this.setState({buttonLoading: false});
            } else {
                const data  = {
                    code: subscription_code,
                    token: email_token
                }
                fetch('https://api.paystack.co/subscription/disable', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer '+window.paystackSecretKey
                    }
                })
                .then(response => response.json())
                .then(json_sub_disable => {
                    console.log(json_sub_disable);
                });
                
                const cancelData = {
                    subscriptionId: this.state.subscription[0].subscriptionId,
	                reason: reason,
                }
                fetch(window.backendDomain+'/api/v1/packages/subscription/cancel', {
                    method: 'PATCH',
                    body: JSON.stringify(cancelData),
                    headers: {
                    'Content-type': 'application/json',
                    'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_sub_cancel => {
                    Swal.fire({  
                        title: json_sub_cancel.title,  
                        icon: 'success',   
                        text: json_sub_cancel.message,  
                      });
                });
                this.setState({buttonLoading: false});
            }
        } 

        return (
            
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            this.state.loading === true ?
                                            <div className="row">
                                                <div className="col-12">
                                                    <center><Loader /></center>
                                                </div>
                                            </div>
                                            :
                                            <div className="form-div-2">
                                                {this.state.subscription[0].cancel === 1?
                                                <center>
                                                    <h4>{this.state.subscription[1].name}<br />Subscription already been canceled!</h4>
                                                </center>
                                                :
                                                <>
                                                    <center>
                                                        <h4>Cancel Subscripiton</h4>
                                                        <h6>Subscripiton: {this.state.subscription[1].name}</h6>
                                                    </center>
                                                    <hr />
                                                    <Form.Label>please why do you want to cancel your suscription on OyaWatch?</Form.Label>
                                                    <br />
                                                    
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="Price is too expensive" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> Price is too expensive </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="It is very slow to stream" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> It is very slow to stream </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="Low quality content" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> Low quality content </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="It is difficult to use" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> It is difficult to use </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="It consumes too much data" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> It consumes too much data </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="Not enough channels"name="reason"  onChange={(e) => this.setState({reason: e.target.value})} /> Not enough channels </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="Not interested in contents" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> Not interested in contents </label>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <label><input type="radio" value="Others" name="reason" onChange={(e) => this.setState({reason: e.target.value})} /> Others </label>
                                                    </Form.Group>
                                                    {submitButton}
                                                </>
                                                }
                                            </div> 
                                        }    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default CancelSubscription
