import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2"; 
import i18n from 'i18n-js';

import Header from "../components/HeaderOut";
import Footer from "../components/Footer";

import loadingImage from '../images/loader.gif';
import Loader from '../components/Loader';

class ResetPassword extends Component {
    constructor(props) {
      super(props)

      this.state = {
        newPassword: '',
        confirmPassword: '',
        buttonLoading: false,
        validUrl: true,
        loading: true,
        email: '',
        token: ''
      }
    }
        
    componentDidMount(){
        let { reset_token, email } = this.props.match.params;
        fetch(window.backendDomain+'/api/v1/auth/check/'+reset_token+'/'+email.toLowerCase(), {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json);
            this.setState({loading: false});
            if(json.code === 200){
                this.setState({validUrl: true});
                this.setState({email: email});
                this.setState({token: reset_token});
            } else {
                this.setState({validUrl: false});
            }
        });
    }
    
    render() {
      const handleSubmit = () => {
        this.setState({buttonLoading: true});
        const newPassword = this.state.newPassword;
        const confirmPassword = this.state.confirmPassword;
        const email = this.state.email;
        const token = this.state.token;
        if(newPassword === "" || confirmPassword === ""){
          Swal.fire({  
            title: 'ERROR',  
            type: 'error',
            icon: 'error',   
            text: 'Enter new and confirm password',  
          });
          this.setState({buttonLoading: false});
        } else {
          const data  = {
            password: newPassword,
            confirmPassword: confirmPassword,
            email: email,
            token: token
          }
          
          fetch(window.backendDomain+'/api/v1/auth/change-password', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
              Swal.fire({  
                position: 'center',  
                icon: 'success',  
                title: json.title,  
                text: json.message, 
                showConfirmButton: false,  
                timer: 4000  
              });
              
            } else {
              Swal.fire({  
                title: json.title,  
                type: 'error',
                icon: 'error',   
                text: json.message,  
              });
            }
            this.setState({buttonLoading: false});
          });
        }
      }
      
      let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
      switch (this.state.buttonLoading) {
        case false:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
          break;
        case true:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
          break;
        default:
          submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
      }
      return (
        <div>
          <Header />
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="form-div">
                    {
                        this.state.loading === true ?
                        <div style={{marginTop: 50}}>
                            <Loader />
                        </div>
                        :
                        <>
                            {
                                this.state.validUrl === false ?
                                <div className="row">
                                    <div className="col-md-12 mt-2">
                                        <center>
                                            <h4>Invalid URL</h4>
                                            <Button style={{display: 'none'}} size="lg" variant="primary" type="submit" className="w-100 mt-3">Open OyaWatch App</Button>
                                        </center>
                                    </div>
                                </div>
                                :
                                <>
                                    <center><h4>{i18n.t('94')}</h4></center>
                                    <hr />
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control size="lg" type="password" placeholder="Enter new password" onChange={(e) => this.setState({newPassword: e.target.value})} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control size="lg" type="password" placeholder="Enter confirm password" onChange={(e) => this.setState({confirmPassword: e.target.value})} />
                                    </Form.Group>
                                    {submitButton}
                                </>
                            }
                        </>
                    }
                  </div>     
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }        
}

export default ResetPassword
