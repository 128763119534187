import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from '../components/Loader';
 
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

class ChannelPreview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            channel: [],
            //epg: [],
            previewLoading: true,
            epgLoading: true,
            dateToday: '',
            timeToday: '',
            dateYesterday: '',
            dateTomorrow: '',

            contents: [],
            visitor: [],
            loading: true,
            block: false,
            loadingEpg: true,

            contentsRelated: [],
            loadingRelated: true,
            categoryId: '',

            epg: [],
            epgShow: false
        }
    }
   
    componentDidMount(){
        var todayDate = new Date();
		var todayYear = todayDate.getFullYear();
        var todayMonth, todayDay, yesterdayDay, tomorrowDay, todayHour, todayMinute;
        //var todaySecond;
		if ((todayDate.getMonth()+1) < 10) {
			todayMonth = "0"+(todayDate.getMonth()+1);
		} else {
			todayMonth = todayDate.getMonth()+1;
		}
		if ((todayDate.getDate()) < 10) {
			todayDay = "0"+todayDate.getDate();
            yesterdayDay = "0"+(todayDate.getDate()-1);
            tomorrowDay = "0"+(todayDate.getDate()+1);
		} else {
		    todayDay = todayDate.getDate();
            yesterdayDay = todayDate.getDate()-1;
            tomorrowDay = todayDate.getDate()+1;
		}
		if ((todayDate.getHours()) < 10) {
			todayHour = "0"+todayDate.getHours();
		} else {
			todayHour = todayDate.getHours();
		}
		if ((todayDate.getMinutes()) < 10) {
			todayMinute = "0"+todayDate.getMinutes();
		} else {
			todayMinute = todayDate.getMinutes();
		}
		if ((todayDate.getSeconds()) < 10) {
			//todaySecond = "0"+todayDate.getSeconds();
		} else {
			//todaySecond = todayDate.getSeconds();
		}
		var todayDateNew = todayDay+"-"+todayMonth+"-"+todayYear;
		var todayTimeNew = todayHour+":"+todayMinute;
        this.setState({dateToday: todayDateNew});
        this.setState({timeToday: todayTimeNew});

		var yesterdayDateNew = yesterdayDay+"-"+todayMonth+"-"+todayYear;
        this.setState({dateYesterday: yesterdayDateNew});
        var tomorrowDateNew = tomorrowDay+"-"+todayMonth+"-"+todayYear;
        this.setState({dateTomorrow: tomorrowDateNew});

        let { tag } = this.props.match.params;
        fetch(window.backendDomain+'/api/v1/contents/channel/'+tag, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json_contents => {
            //console.log(json_contents.data.channelsList[0].category);
            if (json_contents.status === 'OK') {
                this.setState({contents: json_contents.data});
                this.setState({categoryId: json_contents.data.channelsList[0].category});
                fetch('https://ipapi.co/json/', {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    this.setState({visitor: json});
                    this.setState({loading: false});
                    var geotype = json_contents.data.channelsList[0].geoblock;
                    var countries = json_contents.data.channelsList[0].geoblock_countries;
                    var country_code = json.country_code;
                    if(geotype === 1){
                        if(countries.includes(country_code)){
                            this.setState({block: true});
                        }
                    } else if(geotype === 2){
                        if(!(countries.includes(country_code))){
                            this.setState({block: true});
                        }
                    } else {
                        
                    }
                });
                // fetch(backendDomain+'/api/v1/channels/epg/view/'+json.data._id, {
                //     method: 'GET',
                //     headers: {
                //         'Content-type': 'application/json',
                //         'auth-token': localStorage.getItem('token')
                //     }
                // })
                // .then(response => response.json())
                // .then(jsonEpg => {
                //     console.log(jsonEpg);
                //     if (jsonEpg.status === 'OK') {
                //         this.setState({epg: jsonEpg.data.epgData});
                //         this.setState({epgLoading: false});
                //     }
                // });
            }
        });
         
    }
    
    render() { 
        if (this.state.epgShow === false) {
            //var epg = [];
            //var epgCompleted = false;
            var date = new Date();
            var currentHour = date.getHours();
            // yesterday from now to midnight
            var part1 = [];
            var part1Completed = false;
            for (var j = currentHour + 1; j < 24; j++) {
                var from;
                var to;
                var f;
                var t;
                var range;
                var singleEpg;
                if (j === 23) {
                    from = 11 + "PM";
                    to = 12 + "AM";
                } else {
                    f = j;
                    t = j + 1;
                    if (f > 12) {
                        from = (f - 12) + "PM";
                    } else {
                        from = f + "AM";
                    }
                    if (t > 12) {
                        to = (t - 12) + "PM";
                    } else {
                        to = t + "AM";
                    }
                }
                range = from + " - " + to
                singleEpg = ["Yesterday", range, j, 0];
                part1.push(singleEpg);
                //console.log(singleEpg);
                if (j === 23) {
                    part1Completed = true;
                }
            }
    
            // today from midnight to now
            var part2 = [];
            var part2Completed = false;
            for (var i = 0; i <= currentHour; i++) {
                var isLive;
                if (i === currentHour) {
                    isLive = 1;
                } else {
                    isLive = 0;
                }
                if (i === 0) {
                    f = 12;
                } else {
                    f = i;
                }
                t = i + 1;
                if (f > 12) {
                    from = (f - 12) + "PM";
                } else {
                    from = f + "AM";
                }
                
                if (t > 12) {
                    to = (t - 12) + "PM";
                } else {
                    to = t + "AM";
                }
                range = from + " - " + to
                singleEpg = ["Today", range, i, isLive];
                part2.push(singleEpg);
                //console.log(singleEpg);
                if (i === currentHour) {
                    part2Completed = true;
                }
            }
    
            if (part1Completed === true && part2Completed === true) {
                //epg = part1.concat(part2);
                //epgCompleted = true;
                this.setState({epg: part1.concat(part2)})
                this.setState({epgShow: true});
                //console.log(part1.concat(part2))
                //console.log('finished')
            }
            // console.log(part1.length);
            // console.log(part1);
            // console.log(part2.length);
            // console.log(part2);
            // if(epgCompleted === true){
            //     console.log('EPG Completed');
            //     console.log(this.state.epg);
            // }
        }

        // if (this.state.epgShow === true) {
        //     console.log(this.state.epg[0][2]);
        // }

        // if(this.state.loading === false){
        //     console.log(this.state.contents.channelsList[0].logo);
        // }

        if(this.state.loadingRelated === true){
            if(this.state.categoryId !== ''){
                fetch(window.backendDomain+'/api/v1/contents/channels/category/'+this.state.categoryId+'/view', {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json_related => {
                    //console.log(json_related.data);
                    if (json_related.status === 'OK') {
                        this.setState({contentsRelated: json_related.data});
                        this.setState({loadingRelated: false});
                    }
                });
            }
            //console.log('CategoryId ', this.state.categoryId);
        }
        
        // const channelEpgRender = this.state.epg.map((item) => (
        //     <div className="p-1" key={item._id}>
        //         <center>
        //             <h6 style={{margin: 0, marginBottom: 5}}>
        //                 {
        //                     new Date(this.state.dateToday+" "+this.state.timeToday) >= new Date(this.state.dateToday+" "+item.startTime) 
        //                     && 
        //                     new Date(this.state.dateToday+" "+this.state.timeToday) <= new Date(this.state.dateToday+" "+item.stopTime) ?
        //                     <span style={{backgroundColor: 'white', color: 'black', paddingRight: 5, paddingLeft: 5, marginRight: 5, borderRadius: 10}}>
        //                         <i className="fa fa-circle" style={{color: '#D12B1E', fontSize: 14, marginRight: 5}}></i>LIVE
        //                     </span>
        //                 :
        //                     <span></span>
        //                 }
        //                 {item.title}
        //             </h6>
        //         </center>
        //         <img src={backendCms + `/images/channels/${this.state.channel.logo}`} alt="OyaWatch Logo" width="100%" className="image" />
        //         <div className="col-12">
        //             <center>
        //                 <h6 style={{margin: 0}}>{item.startTime} - {item.stopTime}</h6>
        //                 <h6 style={{margin: 0}}>
        //                     { this.state.dateToday === item.startDate ?
        //                         <div>Today</div>
        //                     :
        //                         <div>
        //                         {
        //                             this.state.dateYesterday === item.startDate ?
        //                             <div>Yesterday</div>
        //                             :
        //                             <div>
        //                             {
        //                                 this.state.dateTomorrow === item.startDate ?
        //                                 <div>Tomorrow</div>
        //                                 :
        //                                 item.startDate
        //                             }
        //                             </div>
        //                         }
        //                         </div>
        //                     }
        //                 </h6>
        //             </center>
        //         </div>
        //     </div>
        // )) 
        const checkSubscription = (subscriptions) => {
            var sub_block = false;
            var todayDate = new Date();
            var todayYear = todayDate.getFullYear();
            var todayMonth = "";
            var todayDay = "";
            if ((todayDate.getMonth()+1) < 10) {
                todayMonth = "0"+(todayDate.getMonth()+1);
            } else {
                todayMonth = todayDate.getMonth()+1;
            }
            if ((todayDate.getDate()) < 10) {
                todayDay = "0"+todayDate.getDate();
            } else {
                todayDay = todayDate.getDate();
            }
            var dateNow = todayYear+"-"+todayMonth+"-"+todayDay+"T23:59:59.999Z";
            //console.log(dateNow);
            if (subscriptions.length < 1) {
                sub_block = true;
            } else {
                for(var i = 0; i < subscriptions.length; i++){
                    var sub_end_date = subscriptions[i]['end_date'];
                    if (dateNow > sub_end_date) {
                        sub_block = true;
                    } else {
                        sub_block = false;
                    }
                }
            }
            if (sub_block === true) {
                if (this.state.contents.content[2].status === 1) {
                    return  <button className="btn btn-primary w-100 mt-4 disabled">NO SUBSCRIPTION</button>
                } else {
                    return <Link to={`/channel/play/${this.state.contents.channelsList[0].tag}`}>
                        <button className="btn btn-primary w-100 mt-4">WATCH NOW</button>
                    </Link>
                }    
            } else {
                return <Link to={`/channel/play/${this.state.contents.channelsList[0].tag}`}>
                <button className="btn btn-primary w-100 mt-4">WATCH NOW</button>
            </Link>
            }
        }
        //console.log(this.state.contentsRelated.channelsList);
      return (
          
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    
                    { this.state.loading ?
                        <div style={{marginTop: 50}}>
                            <Loader />
                        </div>
                        :
                        <>
                        {
                            this.state.contents.content[0].status === 1 ?
                            <div className="row">
                                <div className="col-md-6 p-1">
                                    <img src={window.backendUrl+`/${this.state.contents.channelsList[0].logo}`} alt={this.state.contents.channelsList[0].name} className="image" width="100%" />
                                </div>
                                <div className="col-md-6 p-1">
                                    <div className="col-12 channel-description">
                                        <center><h2>{this.state.contents.channelsList[0].name}</h2></center>
                                        {
                                            this.state.block === true ? 
                                                <button className="btn btn-primary w-100 mt-4 disabled">BLOCKED FOR YOUR REGION</button>
                                            :
                                                checkSubscription(this.state.contents.userSubscriptions)
                                        }
                                        <p className="mt-4">
                                            {this.state.contents.channelsList[0].description}
                                        </p>
                                        <h5 className="mt-4">
                                            <Link to={`/channels/${this.state.contents.channelsCategory[0]._id}`} className="link">
                                                {this.state.contents.channelsCategory[0].name}
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        :
                        <></>
                        }
                        </>
                    }
                    
                    <br />
                    <hr />
                    <div className="row">
                        <div className="col-12 p-1">
                            <center><h4>EPG</h4></center>
                        </div>
                        {
                            this.state.epgShow === false || this.state.loading === true ?
                            <div className="mt-5">
                                <Loader />    
                            </div>
                            :
                            //<></>
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                infinite={false}
                                keyBoardControl={true}
                                >
                                {
                                    this.state.epg.map((channelsItem) => ( 
                                        <div className="p-1" key={channelsItem[2]}>
                                            <Link to={`/epg/play/${channelsItem[2]}/${this.state.contents.channelsList[0].tag}`} className="link">
                                                <div className="col-12 pt-2">
                                                    <center><h6>{channelsItem[0]}</h6></center>
                                                </div>
                                                <img src={window.backendUrl+`/${this.state.contents.channelsList[0].logo}`} alt={this.state.contents.channelsList[0].name} className="image" width="100%" />
                                                <div className="col-12 pt-2">
                                                    <center><h6>{channelsItem[1]}</h6></center>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </Carousel>
                        }
                    </div>
                    
                    <br />
                    <hr />
                    <div className="row">
                        <div className="col-12 p-1">
                            <center><h4>Related Contents</h4></center>
                        </div>
                        {
                            this.state.loadingRelated === true ?
                            <div className="mt-5">
                                <Loader />    
                            </div>
                            :
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                infinite={false}
                                keyBoardControl={true}
                                >
                                {
                                    this.state.contentsRelated.channelsList.filter(channels => channels._id !== this.state.contents.channelsList[0]._id).map((channelsItem) => (
                                        <div className="p-1" key={channelsItem._id}>
                                            <Link to={`/channel/${channelsItem.tag}`} className="link">
                                                <img src={window.backendUrl+`/${channelsItem.logo}`} alt={channelsItem.name} className="image" width="100%" />
                                                <div className="col-12 pt-2">
                                                    <center><h6>{channelsItem.name}</h6></center>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </Carousel>
                            }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      );
    }        
}

export default ChannelPreview
