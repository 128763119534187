import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Header from "../components/HeaderOut";
import Footer from "../components/Footer";

import Loader from '../components/Loader';

class ActivateAccount extends Component {
    constructor(props) {
      super(props)

      this.state = {
        loading: true,
        message: '',
        title: '',
        code: 0
      }
    }
        
    componentDidMount(){
        let { reset_token, email } = this.props.match.params;
        fetch(window.backendDomain+'/api/v1/auth/activate/'+reset_token+'/'+email, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json);
            this.setState({message: json.message});
            this.setState({title: json.title});
            this.setState({code: json.code});
            this.setState({loading: false});
        });
    }
    
    render() {
        const refreshPage = () => {
            window.location.reload();
        }
      return (
        <div>
          <Header />
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12 mt-5">
                    {
                        this.state.loading === true ?
                        <div style={{marginTop: 50}}>
                            <Loader />
                        </div>
                        :
                        <>
                            <center>
                                <h4>{this.state.message}</h4>
                                {
                                    this.state.code === 501 ?
                                    <div className="row">
                                        <div className="col-md-4 offset-md-4 mt-2">
                                            <Button onClick={refreshPage} size="lg" variant="primary" type="submit" className="w-100 mt-3">Retry</Button>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </center>
                        </>
                    }
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }        
}

export default ActivateAccount
