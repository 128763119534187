import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from "../components/HeaderOut";
import Footer from "../components/Footer";

class NotFound extends Component {
    
    render() {   
      return (
        <div>
          <Header />
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                    <center><h1 style={{marginTop: 200}}>404 PAGE NOT FOUND</h1></center>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }        
}

export default NotFound
