import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import i18n from 'i18n-js';

import Logo from "../images/logo.png";

class Header extends Component {

  componentDidMount(){
    //fetch('https://ipapi.co/json/', {
      fetch('https://ipinfo.io/?token=d16f793fc3ce26', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(json => {
        //console.log(json);
        //console.log(Object.keys(json).length>3);
        if(Object.keys(json).length>3){
            //localStorage.setItem('visitor', json.country_code);
            localStorage.setItem('visitor', json.country);
        }
    });
  }

    render() {   
      return (
        <Navbar collapseOnSelect expand="lg" className="header" bg="" fixed="top" variant="dark">
            <Container>
            <Navbar.Brand><Link to="/"><img src={Logo} alt="OyaWatch Logo" height="50px" /></Link></Navbar.Brand>
            <Navbar.Toggle className="fa fa-bars white" aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <Nav.Link><Link to="/register" className="link">{i18n.t('17')}</Link></Nav.Link>
              <Nav.Link><Link to="/login" className="link">{i18n.t('3')}</Link></Nav.Link>
            </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
      );
    }        
}

export default Header
