import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import Form from 'react-bootstrap/Form';

import Header from "../components/Header";
import Footer from "../components/Footer";
//import Loader from "../components/Loader";
import { Link } from 'react-router-dom';
import AllChannelsSkeleton from '../components/AllChannelsSkeleton';
 
function ChannelCategory(params) {
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');

    useEffect(()=>{
        fetch(window.backendDomain+'/api/v1/contents/channels/category/'+params.match.params.category+'/view', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json.data);
            if (json.status === 'OK') {
                setContents(json.data);
                setLoading(false);
            }
        });
    }, [params.match.params.category])
    
      return (
        <div>
            <Header />
            <div className="content">
                <div className="container">
                    { loading ?
                        // <div style={{marginTop: 50}}>
                        //     <Loader />
                        // </div>
                        <AllChannelsSkeleton />
                        :
                        <>
                        {
                            contents.content[0].status === 1 ?
                            <>
                            <div className="row">
                                <div className="col-12 p-1 pb-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3><Link to='/channels'  className="link">Channels</Link> / {contents.channelsCategory[0].name}</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control onChange={(e) => setSearch(e.target.value)} size="lg" type="text" placeholder="Search" className="pull-right w-md-50" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                search === '' ?
                                <div className="row">
                                {
                                    contents.channelsList.map((item) => (
                                        <div className="channelDiv p-1">
                                            <Link to={`/channel/${item.tag}`}  className="link">
                                                <img src={window.backendUrl+`/${item.logo}`} alt={item.name} className="image" width="100%" />
                                                <div className="col-12 pt-2">
                                                    <center><h6>{item.name}</h6></center>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                                </div>
                            :
                            <div className="row">
                                {
                                    contents.channelsList.filter( channels => channels.name.toLowerCase().includes((search).toLowerCase()) ).map((item) => (
                                        <div className="channelDiv p-1">
                                            <Link to={`/channel/${item.tag}`}  className="link">
                                                <img src={window.backendUrl+`/${item.logo}`} alt={item.name} className="image" width="100%" />
                                                <div className="col-12 pt-2">
                                                    <center><h6>{item.name}</h6></center>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                            }
                            </>
                        :
                            <div className="row">
                                <div className="col-md-6">
                                    <center><h2>Content Not Available!</h2></center>     
                                </div>
                            </div>
                        }
                        </>
                    }
                </div>
            </div>
            <Footer />
        </div>
      );
    }        


export default ChannelCategory
