import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 

import Header from "../components/Header";
import Footer from "../components/Footer";

import loadingImage from '../images/loader.gif';

class ChangePassword extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            buttonLoading: false,
        }
    }
    render() { 
        const handleSubmit = () => {
            this.setState({buttonLoading: true});
            const oldPassword = this.state.oldPassword;
            const newPassword = this.state.newPassword;
            const confirmPassword = this.state.confirmPassword;
            if(oldPassword === "" ||newPassword === "" || confirmPassword === ""){
                Swal.fire({  
                    title: 'ERROR',  
                    type: 'error',
                    icon: 'error',   
                    text: 'All fields are required',  
                });
                this.setState({buttonLoading: false});
            } else {
                const data  = {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    confirmPassword: confirmPassword
                }
                
                fetch(window.backendDomain+'/api/v1/profile/password/change', {
                    method: 'PATCH',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-type': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    if (json.status === 'OK') {
                        Swal.fire({  
                            position: 'center',  
                            icon: 'success',  
                            title: json.title,  
                            text: json.message, 
                            showConfirmButton: false,  
                            timer: 4000  
                        });
                    } else {
                        Swal.fire({  
                            title: json.title,  
                            type: 'error',
                            icon: 'error',   
                            text: json.message,  
                        });
                    }
                    this.setState({buttonLoading: false});
                });
            }
        }

        let submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        switch (this.state.buttonLoading) {
            case false:
            submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
            break;
            case true:
            submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3 disabled" ><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</Button>
            break;
            default:
            submitButton = <Button size="lg" variant="primary" type="submit" className="w-100 mt-3" onClick={() => handleSubmit()} >Submit</Button>;
        }  
        return (
            <div>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/my-account" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> My Account</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    <div className="form-div">
                                        <center><h4>Change Password</h4></center>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Old Password</Form.Label>
                                            <Form.Control onChange={(e) => this.setState({oldPassword: e.target.value})} size="lg" type="password" placeholder="Enter your old password" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control onChange={(e) => this.setState({newPassword: e.target.value})} size="lg" type="password" placeholder="Enter your new password" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control onChange={(e) => this.setState({confirmPassword: e.target.value})} size="lg" type="password" placeholder="Enter your confirm password" />
                                        </Form.Group>
                                        {submitButton}
                                    </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default ChangePassword
