import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Swal from "sweetalert2";
import Skeleton from "@mui/material/Skeleton";

import Header from "../components/Header";
import Footer from "../components/Footer";
//import Loader from "../components/Loader";
import ChannelsSkeleton from "../components/ChannelsSkeleton";

import { Link } from "react-router-dom";

//import SadFace from "../images/sad-face.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const responsiveVOD = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const responsiveBanner = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contents: [],
      loading: true,
      subscriptions: [],
      loadingSubscription: true,
      freePaid: "",
      notDisplay: true,
      heroBannerHomeTop: [],
      showBannerHomeTop: false,
    };
  }

  componentDidMount() {
    //console.log(window.backendDomain);
    if (this.state.loading === true) {
      fetch(window.backendDomain + "/api/v1/contents/view", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          //console.log(json);
          if (json.status === "OK") {
            this.setState({ contents: json.data });
            this.setState({ loading: false });
          }
        });
    }

    fetch(window.backendDomain + "/api/v1/banner/home-new/desktop", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
        if (json.status === "OK") {
          this.setState({ heroBannerHomeTop: json.data.images });
          if (json.data.status === 1) {
            this.setState({ showBannerHomeTop: true });
          } else {
            this.setState({ showBannerHomeTop: false });
          }
        }
      });
  }

  render() {
    if (
      this.state.loading === false &&
      this.state.loadingSubscription === true
    ) {
      //this.setState({freePaid: this.state.contents.content[2].status});
      var tag = this.state.contents.channelsList[0].tag;
      fetch(window.backendDomain + "/api/v1/contents/channel/" + tag, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((json_contents) => {
          //console.log(json_contents.data.userSubscriptions.length);
          if (json_contents.status === "OK") {
            this.setState({
              subscriptions: json_contents.data.userSubscriptions,
            });
            this.setState({ loadingSubscription: false });
          }
        });
    }

    if (this.state.loadingSubscription === false) {
      if (
        this.state.contents.content[2].status === 1 &&
        this.state.subscriptions.length === 0 &&
        this.state.notDisplay === true
      ) {
        Swal.fire({
          iconHtml:
            '<img src="https://oyawatch.com/sad-face.png" alt="Sad" height="160px" />',
          customClass: {
            icon: "no-border",
          },
          text: "You don't have any subscription, please subscribe to start watching your favorite channels.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Click to Subscribe",
          denyButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location = "/#/subscription/purchase";
          } else if (result.isDenied) {
          }
        });
        this.setState({ notDisplay: false });
      }
    }

    const handleUserAction = (actionType, actionUrl, channelId = null) => {
      if (actionType == "external") {
        window.open(actionUrl, "_blank");
      } else {
        if (channelId == undefined || channelId == "") {
          alert(`Invalid Channel Id, Pls check and verify - ${channelId}`);
        } else {
          window.location.href = `#/channel/${channelId}`;
        }
      }
    };
    return (
      <div>
        <Header />
        <div className="content">
          <div className="container">
            {this.state.showBannerHomeTop === true ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsiveBanner}
                infinite={true}
                keyBoardControl={true}
              >
                {this.state.heroBannerHomeTop.map((banner, index) => (
                  <div className="p-1 m-2" key={index}>
                    {
                      <div
                        className="pointer-cursor"
                        onClick={() =>
                          handleUserAction(
                            banner.action_type,
                            banner.action_url,
                            banner.channel
                          )
                        }
                      >
                        <center>
                          <img
                            src={`${banner.image}`}
                            alt={banner}
                            className="image"
                            width="100%"
                          />
                        </center>
                      </div>
                    }
                  </div>
                ))}
              </Carousel>
            ) : (
              <></>
            )}
            {this.state.loading === true ? (
              // <div className="mt-5">
              //     <Loader />
              // </div>
              <>
                <div className="row">
                  <div className="col-6 p-0">
                    <Skeleton
                      variant="text"
                      width={200}
                      height={50}
                      style={{ backgroundColor: "gray" }}
                    />
                  </div>
                  <div className="col-6 p-0">
                    <Skeleton
                      variant="text"
                      width={200}
                      height={50}
                      style={{ backgroundColor: "gray" }}
                      className="pull-right"
                    />
                  </div>
                </div>
                <ChannelsSkeleton />
                <ChannelsSkeleton />
              </>
            ) : (
              <>
                {this.state.contents.content[0].status === 1 ? (
                  <>
                    <div className="row">
                      <div className="col-6 p-0">
                        <h3>Live Channels</h3>
                      </div>
                      <div className="col-6 p-0">
                        <Link to="/channels/" className="link pull-right">
                          <h6>View Channels</h6>
                        </Link>
                      </div>
                    </div>
                    {this.state.contents.channelsCategory.map((item) => (
                      <div>
                        <div className="row" key={item._id}>
                          <div className="col-12 p-1">
                            <div className="row">
                              <div className="col-6 ">
                                <h4>{item.name}</h4>
                              </div>
                              <div className="col-6">
                                <Link
                                  to={`/channels/${item._id}`}
                                  className="link pull-right"
                                >
                                  <h6>More</h6>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 p-0">
                            <Carousel
                              swipeable={true}
                              draggable={true}
                              showDots={false}
                              responsive={responsive}
                              infinite={false}
                              keyBoardControl={true}
                            >
                              {this.state.contents.channelsList
                                .filter(
                                  (channels) => channels.category === item._id
                                )
                                .map((channelsItem) => (
                                  <div className="p-1" key={channelsItem._id}>
                                    <Link
                                      to={`/channel/${channelsItem.tag}`}
                                      className="link"
                                    >
                                      <img
                                        src={
                                          window.backendUrl +
                                          `/${channelsItem.logo}`
                                        }
                                        alt={channelsItem.name}
                                        className="image"
                                        width="100%"
                                      />
                                      <div className="col-12 pt-2">
                                        <center>
                                          <h6>{channelsItem.name}</h6>
                                        </center>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                {this.state.contents.content[1].status === 1 ? (
                  <>
                    <br />
                    <hr />
                    <div className="row">
                      <div className="col-6 p-0">
                        <h3>Video on Demand</h3>
                      </div>
                      <div className="col-6 p-0">
                        <Link to="/vods" className="link pull-right">
                          <h6>View VOD</h6>
                        </Link>
                      </div>
                    </div>
                    {this.state.contents.vodsCategory.map((item) => (
                      <div>
                        <div className="row" key={item._id}>
                          <div className="col-12 p-1">
                            <div className="row">
                              <div className="col-6 ">
                                <h4>{item.name}</h4>
                              </div>
                              <div className="col-6">
                                <Link
                                  to={`/vods/${item._id}`}
                                  className="link pull-right"
                                >
                                  <h6>More</h6>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 p-0">
                            <Carousel
                              swipeable={true}
                              draggable={true}
                              showDots={false}
                              responsive={responsiveVOD}
                              infinite={false}
                              keyBoardControl={true}
                            >
                              {this.state.contents.vodsList
                                .filter((vods) => vods.category === item._id)
                                .map((vodItem) => (
                                  <div className="p-1" key={vodItem._id}>
                                    <Link
                                      to={`/vod/${vodItem.tag}`}
                                      className="link"
                                    >
                                      <img
                                        src={
                                          window.backendUrl +
                                          `/${vodItem.image}`
                                        }
                                        alt={vodItem.name}
                                        className="image"
                                        width="100%"
                                      />
                                      <div className="col-12">
                                        <center>
                                          <h6>{vodItem.name}</h6>
                                        </center>
                                      </div>
                                    </Link>
                                  </div>
                                ))}
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Dashboard;
