import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function AuthenticateUser() {
    const { token, page } = useParams();
    const history = useHistory()
    
    useEffect(() => {
        fetch(window.backendDomain+'/api/v1/profile/view', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'auth-token': token
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json)
            if (json.status === 'OK') {
                localStorage.setItem('token', token);
                if(localStorage.getItem('token')){
                    history.push(`/${page}`);
                    //history.push('/subscription/purchase');
                } else {
                    localStorage.setItem('token', token);
                    history.push(`/${page}`);
                    //history.push('/subscription/purchase');
                }
            } else {
                Swal.fire({  
                    title: json.title,  
                    icon: 'error',   
                    text: json.message,  
                }).then(function() {
                    history.push(`/login`);
                });
            }
        });
    }, [history, page, token]);

    return (
        <></>
    );
}