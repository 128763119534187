import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2"; 
import Modal from 'react-bootstrap/Modal';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

class MyDevices extends Component {
    constructor(props) {
        super(props)
  
        this.state = {
            showModal: false,
            deviceId: '',
            contents: [],
            loading: true,
        }
    }
    componentDidMount(){
        fetch(window.backendDomain+'/api/v1/profile/devices', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'auth-token': localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then(json => {
            //console.log(json);
            if (json.status === 'OK') {
                this.setState({contents: json.data});
                this.setState({loading: false});
            } else {
                
            }
        });
    }

    render() { 
        const handleClose = () => {
            this.setState({showModal: false});
            this.setState({deviceId: ''});
        }

        const handleOpen = (device_id) => {
            this.setState({showModal: true});
            this.setState({deviceId: device_id});
        }

        const handleSubmit = (deviceId) => {
            this.setState({loading: true});
            if(deviceId === ""){
                Swal.fire({  
                    title: 'ERROR',  
                    type: 'error',
                    icon: 'error',   
                    text: 'Invalid Request, try again!',  
                });
                this.setState({loading: false});
            } else {
                const data = {
                    deviceId: deviceId
                }
                fetch(window.backendDomain+'/api/v1/profile/remove-device', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-type': 'application/json',
                        'auth-token': localStorage.getItem('token')
                    }
                })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    if (json.status === 'OK') {
                        Swal.fire({  
                            position: 'center',  
                            icon: 'success',  
                            title: json.title,  
                            text: json.message, 
                            showConfirmButton: false,  
                            timer: 4000  
                        });
                        this.setState({showModal: false});
                        if(deviceId === "fcb9ef9019deac5377532c3824c3061c"){
                            localStorage.removeItem("token");
                            window.location="/#/login";
                        }
                    } else {
                        Swal.fire({  
                            title: json.title,  
                            type: 'error',
                            icon: 'error',   
                            text: json.message,  
                        });
                    }
                });
                this.setState({loading: false});
            }
        } 

        return (
            <div>
                <Modal show={this.state.showModal} onHide={handleClose}>
                <Modal.Body>
                    <center>
                    <h5 className="mt-5">Are you sure you want to logout the device?</h5>
                    <div className="row mt-5 mb-5">
                        <div className="col-6">
                        <Button variant="primary" className="w-100" onClick={() => handleSubmit(this.state.deviceId)}>
                            Yes
                        </Button>
                        </div>
                        <div className="col-6">
                        <Button variant="primary" className="w-100" onClick={handleClose}>
                            No
                        </Button>
                        </div>
                    </div>
                    </center>
                </Modal.Body>
                </Modal>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 p-1 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/manage-subscription" className="link"><h3><i class="fa fa-chevron-left" style={{zoom: 0.75}}></i> Manage Subscription</h3></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                       <center>
                                            <h4>My Devices</h4>
                                        </center>
                                        <hr />
                                        { this.state.loading ?
                                            <div className="row">
                                                <div className="col-12">
                                                    <center><Loader /></center>
                                                </div>
                                            </div>
                                            :
                                            <>
                                            <table border="1px" rule="all" cellPadding="5px" width="100%">
                                                <tr>
                                                    <th>Device</th>
                                                    <th>Last Login</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                {
                                                    this.state.contents.map((item) => (
                                                        <tr key={item._id}>
                                                            <td>{item.device_name}</td>
                                                            <td>{(item.last_login_date).substr(0, 10)} {(item.last_login_date).substr(11, 8)}</td>
                                                            <td>
                                                                {
                                                                    item.status === 1 ?
                                                                    <div style={{backgroundColor: 'green', padding: 2, borderRadius: 20, width: '100%', textAlign: 'center'}}>Login</div>
                                                                    :
                                                                    <div style={{backgroundColor: '#D12B1E', padding: 2, borderRadius: 20, width: '100%', textAlign: 'center'}}>Logout</div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.status === 1 ?
                                                                        <Button onClick={() => handleOpen(item.device_id)} size="sm" variant="primary" type="submit" className="w-100" >Logout</Button>
                                                                    :
                                                                        <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }        
}

export default MyDevices
